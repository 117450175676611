import { message, Cascader } from 'antd'
import React, { useEffect, useState } from 'react'

import { ProvinceCityItem } from 'src/types/hotline'
import { getProvinceCity } from '@services/lead/hotline'

type Props = {
  placeholder?: string;
  style?: React.CSSProperties;
  value?: string[];
  onChange?: (value: string[]) => void;
};

const CascaderProvinceCity: React.FC<Props> = (props) => {
  const { style = {}, value = [], onChange = () => {}, placeholder = '请输入线索来源' } = props
  const [options, setOptions] = useState<ProvinceCityItem[]>([])

  useEffect(() => {
    (async () => {
      const [data, err] = await getProvinceCity()
      if (!err) {
        setOptions(data)
      } else {
        message.error('获取线索来源列表失败')
      }
    })()
  }, [])

  return (
    <Cascader
      fieldNames={{ label: 'area_name', value: 'area_name', children: 'children' }}
      value={value}
      style={style}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      showSearch
    />
  )
}

export default CascaderProvinceCity
