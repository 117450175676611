import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Table, message } from 'antd'
import { ColumnsType } from 'antd/es/table/interface'
import { trim } from 'lodash'
import { getCallHistory } from '@services/lead/hotline'
import { ClueTagItem, NameCodeItem, ICallHistoryProps, ForwardedEventRef, GetCallHistoryItem, DataType } from 'src/types/hotline'
import { envMap, Environment } from 'src/library/rum'

const getLeadTagsText = (leadTags: string[], leadTagList: ClueTagItem[]) => {
  const [oneTag, twoTag] = leadTags
  const oneTagTxt = leadTagList.find((i) => i.tag_code === oneTag)?.tag_name ?? ''
  const twoTagTxt = twoTag ? leadTagList.find((i) => i.tag_code === twoTag)?.tag_name : ''
  if (twoTagTxt) {
    return `${oneTagTxt}/${twoTagTxt}`
  }
  return `${oneTagTxt}`
}
const getIntentionText = (oneIntention: string, twoIntention: string, intentionList: NameCodeItem[]) => {
  const obj = intentionList.find((i) => i.code === oneIntention)
  if (obj) {
    const oneTagTxt = obj?.name ?? ''
    const twoTagTxt = obj?.children?.find((i) => i.code === twoIntention)?.name ?? ''
    return `${oneTagTxt}/${twoTagTxt}`
  }
  return ''
}
const env = envMap[window.location.hostname] || Environment.production
const TaskIds
  = env === 'production' ? [15544, 14012, 17680, 17634] : [1199, 1072, 1558810, 158771]

const CallHistory = (props: ICallHistoryProps, ref: ForwardedEventRef) => {
  const { clueTagList = [], intentionList = [] } = props
  const [tableData, setTableData] = useState<GetCallHistoryItem[]>([])
  const getHistoryList = async (val: { uin?: string; phone?: string; company?: string }) => {
    const { uin, phone, company } = val
    const innerUin = trim(uin)
    const innerPhone = trim(phone)
    const innerCompany = trim(company)
    if (innerUin === '' && innerPhone === '' && innerCompany === '') {
      return
    }
    const param = {
      taskIds: TaskIds,
      ...(innerUin ? { uin: innerUin } : {}),
      ...(innerPhone ? { phone: innerPhone } : {}),
      ...(innerCompany ? { company: innerCompany } : {}),
    }
    const [data, err] = await getCallHistory(param)
    if (err) {
      message.error(err.message || '获取历史记录失败')
    } else {
      const list = data.List ?? []
      setTableData(list)
    }
  }

  useImperativeHandle(ref, () => ({
    getHistoryList,
  }))
  const columns: ColumnsType<DataType> = [
    {
      title: '流转时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
    },
    {
      title: '流转类型',
      dataIndex: 'TaskId',
      key: 'TaskId',
      render: (val) => {
        let str = '未知'
        const callIn = [15544, 1199, 17680, 17634, 158771, 1558810].includes(val) ? '呼入' : ''
        const callOut = [14012, 1072].includes(val) ? '呼出' : ''

        if (callIn) {
          str = callIn
        } else if (callOut) {
          str = callOut
        }
        return <>{str}</>
      },
    },
    {
      title: '客户名称',
      dataIndex: 'Company',
      key: 'Company',
    },
    {
      title: '联系人姓名',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: '客户意向', // customer_intention
      dataIndex: 'customerIntention',
      key: 'customerIntention',
      render: (_, { IntentionLevel, IntentionSecondLevel }) => (
        <>{getIntentionText(IntentionLevel, IntentionSecondLevel, intentionList)}</>
      ),
    },
    {
      title: '线索标签', // clue_tag
      dataIndex: 'LeadTags',
      key: 'LeadTags',
      render: (val) => <>{getLeadTagsText(val, clueTagList)}</>,
    },
    {
      title: '客户需求', // customer_demand
      dataIndex: 'CustomerDemand',
      key: 'CustomerDemand',
      width: '20%',
      ellipsis: true,

    },
  ]
  return (
    <Table
      bordered
      sticky
      columns={columns}
      rowKey="Id"
      dataSource={tableData}
      pagination={{
        showSizeChanger: true,
        showTotal: () => `共 ${tableData.length} 条记录`,
      }}
    />
  )
}

export default forwardRef(CallHistory)
