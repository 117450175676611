// 职务列表
export const DutiesList = [
  '总经理及以上',
  'HR经理或员工',
  'IT经理或员工',
  '财务经理或员工',
  '行政经理或员工',
  '销售经理或员工',
  '市场经理或员工',
  '技术经理或员工',
  '生产部经理或员工',
  '采购部经理或员工',
  '物流部经理或员工',
  '客户服务部经理或员工',
  '法务部经理或员工',
  '公关部经理或员工',
  '其他部门员工',
  '以上皆不是',
]
// 公司规模列表
export const CompanySize = ['20人以下', '20-100人', '100-500人', '500-1000人', '1000-5000人', '5000人以上']

// 主销售类型字典
export const MainSalesTypes: { [key: number]: string } = {
  0: '没主业务',
  1: '行业直销(KA)',
  2: '区域直销',
  3: '渠道销售',
  4: '创投BD',
}

// 电话号码正则表达式
export const TelphoneReg = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/

export const LowIntentionOptions = [
  'irrelevant', // 售后咨询/非腾讯相关问题
  'tencent cloud problem', // 售后咨询/腾讯云产品问题
  'Product use problem', // 售后咨询/会议产品使用问题
  'second inbound', // 无需求/二次催促
  'unable to convert', // 无需求/无法转化
  'only guided authentication', // 无需求/仅引导认证
]

// 线索来源 (/clue-collect页面使用)
export const ClueSourceCommon = [
  '官网',
  '网页端控制台',
  '小程序',
  '公众号',
  '营销物料',
  '市场活动',
  '搜索',
  '其他',
]
