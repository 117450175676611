/**
 * 基础请求类
 * @author jasonelchen
 */
/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { API_HOST, codeList, CSRF_COOKIE_NAME, CSRF_HEADER_NAME, ORIGIN_TCC_TOKEN, unauthorizedAPI, notFoundAPI } from '@configs'
import { tryCatch } from './try-catch'
import { CommonError, CommonList } from '@types'
import { get as getCookieByName } from 'js-cookie'
import { Modal, message as messageApi } from 'antd'
import { uniqBy } from 'lodash'

/**
 * 原始请求方法
 * @usage
 *
 * ```
 * http({ method: 'GET', data: { a: 1 } })
 * http.get('/', { params: { a: 1 } })
 * http.post('/', { a: 1 })
 * http.del('/', { params: { a: 1 } })
 * http.put('/', { a: 1 })
 * http.patch('/', { a: 1 })
 * ```
 */
export const http = axios.create({
  baseURL: API_HOST,
  timeout: 30 * 1000,
  withCredentials: true,
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
})

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest' // 兼容智能网关，当登录态过期之后，会返回 401 而不是跳转开
if (getCookieByName(ORIGIN_TCC_TOKEN)) {
  http.defaults.headers.common[ORIGIN_TCC_TOKEN] = getCookieByName(ORIGIN_TCC_TOKEN) ?? '' // 如果header里有此字段说明此时是模拟状态
}

/**
 * 处理响应
 * 1. 针对 code 不为 0，或者 http 状态码大于等于 400，一律算作失败
 * 2. 将响应的错误格式化为 `CommonError`
 */
export const setupInterceptors = history => {
  function jumpLogin () {
    history.push(
      {
        pathname: '/login',
        search: `?callback=${encodeURIComponent(window.location.pathname + window.location.search)}`,
      },
    )
  }
  http.interceptors.response.use(
    (res: AxiosResponse) => {
      // 兼容后台直接 CGW 的返回
      const code = parseInt(res?.data?.code ?? res?.data?.returnCode)
      let message = res?.data?.message ?? res?.data?.returnMessage ?? ''
      if (!codeList.ok.includes(code)) {
        const interfaceName = JSON.parse(res.config.data)?.interface?.interfaceName || ''
        if (codeList.redirectToLogin.includes(code)) {
          // 根据白名单决定，是否跳转
          try {
            // 不在白名单，一律跳转
            if (!unauthorizedAPI.includes(interfaceName)) {
              jumpLogin()
              // window.location.href = `/login?callback=${encodeURIComponent(window.location.pathname + window.location.search)}`
            }
          } catch (e) {
            jumpLogin()
            // window.location.href = `/login?callback=${encodeURIComponent(window.location.pathname + window.location.search)}`
          }
        }
        if (codeList.alert.includes(code)) {
          console.log(code, message)
          Modal.error({ title: '操作失败', content: message })
          return new Promise(() => {}) // 永远pending 不会到达业务层
        }
        if (codeList.notFound.includes(code)) {
          if (notFoundAPI.includes(interfaceName)) {
            window.location.href = '/404'
          }
        }
        if (message?.includes('[TencentCloudSDKError]')) {
          const MessageMap = {
            'OperationDenied.ServiceBusy': '服务异常',
            'InvalidParameterValue': '参数非法',
            'UnauthorizedOperation.UinNoAuth': '当前用户无权限',
            'InvalidParameterValue.NotFound': '数据不存在',
            'FailedOperation.OrderStatusHasChanged': '选品单状态不匹配',
            'FailedOperation.SelectionExist': '待确认的选品单已存在，不能重复创建',
          }
          message = MessageMap[Object.keys(MessageMap)?.filter(o => message?.includes(o))?.[0]]
        }

        return Promise.reject({
          code,
          message: typeof message === 'string' ? message.slice(0, 200) : message,
          status: 200,
          rawResponse: res,
          rawError: null,
        } as CommonError)
      }

      return res
    },
    (err: AxiosError) => {
      const status = err?.response?.status
      const statusText = err?.response?.statusText
      const code = err?.response?.data?.code ?? status
      const message = err?.response?.data?.code ?? statusText ?? ''

      if (status === 401) {
        const errorMessage = err?.response?.data?.returnMessage
        if (err?.response?.data?.returnCode === 603005) {
          errorMessage && messageApi.error(`${errorMessage}: 当前IP暂不运行登录系统，请联系管理人员`)
        }
        // 根据白名单决定，是否跳转
        try {
          const interfaceName = JSON.parse(err.config.data)?.interface?.interfaceName || ''
          // 不在白名单，一律跳转
          if (!unauthorizedAPI.includes(interfaceName)) {
            jumpLogin()
            // window.location.href = `/login?callback=${encodeURIComponent(window.location.pathname + window.location.search)}`
          }
        } catch (e) {
          jumpLogin()
          // window.location.href = `/login?callback=${encodeURIComponent(window.location.pathname + window.location.search)}`
        }
      }

      return Promise.reject({
        code,
        message: typeof message === 'string' ? message.slice(0, 30) : message,
        status,
        rawResponse: null,
        rawError: err,
      } as CommonError)
    },
  )
}

/**
 * 这里的ServiceType是常用的返回类型为数组或分页的数组类型
 */
export type ServiceType<F, T> = (filter: F) => Promise<[null, CommonError] | [CommonList<T> | T[], null]>

const requestResolve = (config: AxiosRequestConfig) => {
  (config as any).requestTime = new Date().getTime()
  return config
}

http.interceptors.request.use(requestResolve)

/**
 * 经过 tryCatch 封装的方法
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, AxiosError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await request({ method: 'GET', data: { a: 1 } })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function request<T = any> (config: AxiosRequestConfig) {
  return tryCatch<T, AxiosError>(http(config).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} url 请求路径
 * @param data
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await get('/', { a: 1 })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function get<T = any> (url: string, data: any = {}, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.get(url, { ...config, params: data }).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} url 请求路径
 * @param {any} data 请求的数据
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await post('/', { a: 1 })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function post<T = any, D = any> (url: string, data?: D, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.post(url, data, config).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} url 请求路径
 * @param {any} data 请求的数据
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await put('/', { a: 1 })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function put<T = any, D = any> (url: string, data?: D, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.put(url, data, config).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} url 请求路径
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await del('/', { params: { a: 1 } })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function del<T = any> (url: string, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.delete(url, config).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} url 请求路径
 * @param {any} data 请求的数据
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await patch('/', { a: 1 })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function patch<T = any, D = any> (url: string, data?: D, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.patch(url, data, config).then(res => res.data.data))
}

/**
 * 经过 tryCatch 封装的方法
 * @param {string} interfaceName 请求路径
 * @param {any} data 请求的数据
 * @param {AxiosRequestConfig} config axios 原始配置
 * @return {[T, CommonError]} 返回一个元组，第一个为请求的结果，第二个为错误对象
 * ```
 * const [res, err] = await patch('/', { a: 1 })
 * if (err) { return console.error(err) }
 * // 安全的访问 res
 * console.log(res.code)  // 0
 * ```
 */
export function requestApi<T = any> (interfaceName: string, data?: any, config?: AxiosRequestConfig): Promise<[null, CommonError] | [T, null]> {
  const cgwRequest = {
    version: 1,
    webVersion: process.env.BUILD_DATE,
    componentName: 'tcc-web',
    eventId: `${Date.now()}.${Math.random()}`,
    interface: {
      interfaceName,
      para: data || {},
    },
  }

  return tryCatch<T, CommonError>(http.post(`/api/interface?${interfaceName}`, cgwRequest, config).then(res => {
    // es数据重复，临时过滤方案，待后端解决后可移除
    if (interfaceName === 'SearchCustomerName') return uniqBy(res.data.data?.list, 'eid')
    return res.data.data
  }))
}

export function requestApiV2<T = any> (interfaceName: string, data?: any, config?: AxiosRequestConfig): Promise<[null, CommonError] | [T, null]> {
  const cgwRequest = {
    version: 1,
    webVersion: process.env.BUILD_DATE,
    componentName: 'tcc-web',
    eventId: `${Date.now()}.${Math.random()}`,
    interface: {
      interfaceName,
      para: data || {},
    },
  }

  return tryCatch<T, CommonError>(http.post(`/api/v2/interface?${interfaceName}`, cgwRequest, config).then(res => res.data.data))
}

export function getFrom400<T = any> (url: string, data: any = {}, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.get(url, { ...config, params: data }).then(res => res.data.result))
}

export function postFrom400<T = any, D = any> (url: string, data?: D, config?: AxiosRequestConfig) {
  return tryCatch<T, CommonError>(http.post(url, data, config).then(res => res.data.result))
}
