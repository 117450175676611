import { useMemoizedFn } from 'ahooks'
import { message, Spin } from 'antd'
import { trim } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { getCidByCustomerName, getCustomerInfoByFields } from '@services/lead/hotline'
import { MainSalesTypes } from '../constant'
type Props = {
  value?: string;
  companyName: string;
  onChange?: (value: string) => void;
};

const MajorSalesType: React.FC<Props> = (props) => {
  const { value = '', onChange = () => {}, companyName } = props
  const [salesType, setSalesType] = useState(-1)
  const [loading, setLoading] = useState(false)
  const timeRef = useRef<number | null>(null)

  const findSalesTypeByName = useMemoizedFn((name: string) => {
    if (timeRef.current) {
      window.clearTimeout(timeRef.current)
    }
    timeRef.current = window.setTimeout(async () => {
      setLoading(true)
      const [cidRes, err] = await getCidByCustomerName({ name })
      if (err) {
        setSalesType(-new Date())
        message.error('获取主销售类型错误（getCidByCustomerName）')
      } else if (cidRes) {
        if (cidRes?.Cid) {
          const [typeRes, ferr] = await getCustomerInfoByFields({ Cid: cidRes.Cid })
          if (ferr) {
            setSalesType(-new Date())
            message.error('获取主销售类型错误（getCustomerInfoByFields）')
          } else {
            setSalesType(typeRes.major_sales_type)
          }
        } else {
          setSalesType(-new Date())
        }
      }
      setLoading(false)
    }, 800)
  })

  const salesTypeString = useMemo(() => {
    return salesType < 0 ? '未知' : MainSalesTypes[salesType]
  }, [salesType])

  useEffect(() => {
    onChange(salesTypeString)
  }, [salesTypeString])

  useEffect(() => {
    const name = trim(companyName)
    if (trim(name)) {
      findSalesTypeByName(name)
    }
  }, [companyName])

  return (
    <Spin spinning={loading}>
      <span>{value}</span>
    </Spin>
  )
}

export default MajorSalesType
