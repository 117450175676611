/**
 * 侧边栏
 * @author jasonelchen
 */
import React, { FunctionComponent, Key, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'
import { message, Switch, Tag, Image } from 'antd'
import store from '@store'
import './style'
import { StaffisAssign } from '@types'
import { User } from './user'
import { CheckInOrOut } from '@services/session'
import { AuthPointType } from '@configs/auth-point'
import classNames from 'classnames'
import Favicon from './favicon.png'
// import { moduleId } from '@configs'

export type MenuItem = {
  // eslint-disable-next-line react/no-unused-prop-types
  key: Key

  // 菜单文案
  title: string

  // 菜单地址（当有子菜单的时候，会被忽略）
  url?: string

  // 图标
  Icon?: React.ReactNode

  // 是否精确匹配
  exact?: boolean

  // 权限点 或的关系，只要有一个满足就显示
  // eslint-disable-next-line react/no-unused-prop-types
  authPoints?: Array<AuthPointType>

  // 仅在所列出的模块显示
  // eslint-disable-next-line react/no-unused-prop-types
  belongModule?: number[]
  // 子菜单
  subMenus?: MenuItem[]
}

export interface SiderBarProps {
  // 侧边栏菜单配置
  menus: MenuItem[]
}

export const SiderBar: FunctionComponent<SiderBarProps> = props => {
  const { menus } = props
  const { userInfo, getUserInfo, moduleList } = store.useSession()
  const [loadingAssign, setLoadingAssign] = useState(false)
  const belongModuleName = useMemo(() => moduleList?.find?.((item) => item.belongModule === userInfo.belongModule)?.belongModuleName, [moduleList, userInfo])
  const handleSwitchAssign = async (checked: boolean) => {
    // 调签入签出接口并更新userInfo
    const isAssign = checked ? StaffisAssign.YES : StaffisAssign.NO
    setLoadingAssign(true)
    const [, err] = await CheckInOrOut({ id: userInfo.id, isAssign })
    if (err) {
      message.error(err.message)
      setLoadingAssign(false)
      return
    }
    // if (userInfo.useT3C && userInfo.belongModule === moduleId.MID_LONG_TAIL_CLEAN_ID) {
    //   isAssign ? await window.tccc.Agent.setStatus({ status: 'free' }) : await window.tccc.Agent.setStatus({ status: 'rest' })
    // }
    await getUserInfo()
    setLoadingAssign(false)
  }

  const [IsHidden, setIsHidden] = useState(false)

  return (
    <>
      {!IsHidden && (
        <div className="components-sider-bar">
          <h1 className="title" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {/* a标签的字体是蓝色的，这里改回黑色 */}
            <a href="/" style={{ color: '#17293e', display: 'flex', alignItems: 'flex-start' }}>
              <Image className="mr10 mb10" style={{ paddingRight: 5 }} preview={false} src={Favicon} width={32} />腾讯云呼叫中心
            </a>
            {/* 因为tag的marginRight不为0，用flex居中的时候会出现偏差，所以这里置0 */}
            {/* 有时belongModuleNam是undefined */}
            {belongModuleName && <Tag style={{ marginRight: '0' }} color="blue" className="mb15">{belongModuleName}</Tag>}
            <Switch
              loading={loadingAssign}
              checkedChildren="工作中"
              unCheckedChildren="暂时离开"
              checked={userInfo.isAssign === StaffisAssign.YES}
              onChange={checked => handleSwitchAssign(checked)}
            />
          </h1>
          <section>
            {menus.map(menu => (
              <Menu key={menu.key} {...menu} />
            ))}
          </section>
          <User />
        </div>
      )}

      {!IsHidden && <div className="sider-handler-container"><div className="sider-handler-show" onClick={() => setIsHidden(true)} /></div>}
      {IsHidden && <div className="sider-handler-hidden" onClick={() => setIsHidden(false)} />}
    </>
  )
}

const Menu: FunctionComponent<MenuItem> = props => {
  const { title, url, Icon, exact = true, subMenus } = props

  return (
    <div className="menu-group">
      {props.subMenus && Array.isArray(props.subMenus) && (
        <h3 className="menu-title">{Icon} {title}</h3>
      )}
      <ul className="menu-list">
        {
          props.subMenus && Array.isArray(props.subMenus)
            ? subMenus.map(menu => {
              const { key, title, url, exact = true, Icon } = menu

              return (
                <MenuNode key={key} title={title} url={url} exact={exact} Icon={Icon} />
              )
            })
            : (
              <MenuNode title={title} url={url} exact={exact} Icon={Icon} isTop />
            )
        }
      </ul>
    </div>
  )
}

interface MenuNodeProps extends Pick<MenuItem, 'Icon' | 'exact' | 'title' | 'url'> {
  isTop?: boolean // 是否是顶层
}

const MenuNode: FunctionComponent<MenuNodeProps> = props => {
  const { title, url, exact = true, Icon, isTop = false } = props
  const isOuterLink = useMemo(() => (url.startsWith('http://') || url.startsWith('https://')), [url])

  return (
    <li className="menu-item">
      {
        isOuterLink
          ? (
            <a className="menu-item-link" href={url} target="_blank" rel="noopener noreferrer">
              {Icon}
              <span className="label">{title}</span>
              {isOuterLink && (
                <LinkOutlined />
              )}
            </a>
          )
          : (
            <NavLink exact={exact} className={classNames('menu-item-link', { 'ml10': !isTop })} to={url}>
              {Icon}
              <span className="label">{title}</span>
            </NavLink>
          )
      }
    </li>
  )
}
