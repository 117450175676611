import React, { useEffect, useState } from 'react'
import { message, Select } from 'antd'
import { getPrimaryIndustryIncome } from '@services/lead/hotline'
import { PrimaryIndustryIncomeItem } from 'src/types/hotline'

type Props = {
  placeholder?: string;
  style?: React.CSSProperties;
  value?: number[];
  onChange?: (value: number[]) => void;
};

const SelectIndustry: React.FC<Props> = (props) => {
  const { style = {}, value = [], onChange = () => {}, placeholder = '请输入客户名称' } = props
  const [option, setOption] = useState<PrimaryIndustryIncomeItem[]>([])
  useEffect(() => {
    getPrimaryIndustryIncome().then((res) => {
      const [data, err] = res
      console.log('res::', data)

      if (err) {
        console.log('🚀 ~ getPrimaryIndustryIncome ~ err:', err)
        message.error('获取行业信息失败')
      } else {
        setOption(data)
      }
    })
  }, [])
  return (
    <Select
      style={style}
      value={value}
      showSearch
      placeholder={placeholder}
      optionFilterProp="label"
      onChange={onChange}
      filterOption
      options={option.map((i) => ({
        label: i.name,
        value: i.name,
      }))}
    />
  )
}

export default SelectIndustry
