/**
 * 登录
 * @author jasonelchen
 */
import React, { FunctionComponent, useEffect } from 'react'
import { message, Spin } from 'antd'
import { stringify } from 'qs'
import { useHistory } from 'react-router-dom'
import store from '@store'

export const Login: FunctionComponent<{ gettingAuth?: boolean }> = props => {
  const { gettingAuth = false, children } = props
  const { logged, userInfo, getUserInfo, initT3cSdk, getHotline400ConfigOnce } = store.useSession()
  const { push } = useHistory()

  // 拉取用户信息
  useEffect(() => {
    ;(async () => {
      try {
        const userInfo = await getUserInfo()
        if (userInfo.useT3C) {
          await initT3cSdk()
          getHotline400ConfigOnce()
        }
      } catch (e) {
        if (e.code === 401) {
          return push(`/login?${stringify({ callbackUrl: window.location.pathname + window.location.search })}`)
        }

        return message.error(`登录失败，请刷新页面重试(${e.message})`)
      }
    })()
  }, [])

  return (
    <>
      {logged && userInfo.salesName && !gettingAuth
        ? children
        : (
          <div className="main-app page-center">
            <div className="login-loading">
              <Spin spinning tip="加载中..." />
            </div>
          </div>
        )}
    </>
  )
}
