import React, { useEffect, useState } from 'react'
import { message, TreeSelect } from 'antd'
import { ProductTreeItem } from 'src/types/hotline'
import { getProductTree } from '@services/lead/hotline'

type Props = {
  placeholder?: string;
  style?: React.CSSProperties;
  value?: number[];
  onChange?: (value: number[]) => void;
};

const TreeSelectProduct: React.FC<Props> = (props) => {
  const { style = {}, value = [], onChange = () => {}, placeholder = '请选择咨询产品' } = props
  const [option, setOption] = useState<ProductTreeItem[]>([])
  // console.log('🚀 ~ option:', option);
  useEffect(() => {
    getProductTree().then((res) => {
      const [data, err] = res
      if (err) {
        message.error('获取咨询产品失败')
      } else {
        setOption(
          data.map((item) => {
            const code = JSON.stringify({ Code: item.code, Name: item.name })
            let child
            if (item?.child) {
              child = item?.child?.map((child) => {
                return {
                  ...child,
                  code: `${code}#${JSON.stringify({ Code: child.code, Name: child.name })}`,
                }
              })
            }
            return {
              id: code,
              ...item,
              code,
              child,
            }
          }),
        )
      }
    })
  }, [])
  return (
    <TreeSelect
      allowClear
      placeholder={placeholder}
      treeNodeFilterProp="name"
      filterTreeNode
      style={style}
      fieldNames={{
        label: 'name',
        value: 'code',
        children: 'child',
      }}
      value={value}
      showSearch
      treeDataSimpleMode
      treeData={option}
      onChange={onChange}
    />
  )
}

export default TreeSelectProduct
