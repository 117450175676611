import { requestApiV2 } from '@library'
import { T3CConfig } from '@types'
export function getT3cSdkUrl () {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/CreateLoginToken')
}

export function addT3CConfig (payload: T3CConfig) {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/CreateBizT3CApp', payload)
}

export function getT3CConfig () {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/GetBizT3CApp')
}

export function editT3CConfig (payload: T3CConfig) {
  return requestApiV2('/trpc.tcc.tcc_main.T3C/UpdateBizT3CApp', payload)
}
