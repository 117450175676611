/**
 * 通话记录
 * @author fredhaochen
 */
import React, { useMemo } from 'react'
import { CommonFilters, PageHeader, ReplayRecord, TableDownload, UserAvatar } from '@components'
import { Card, Table, Tabs, Tooltip } from 'antd'
import { exportCallRecordList, getCallRecordList, exportT3CCallRecordList, getT3CCallRecordList } from '@services/customer'
import { CallRecordListFilter, CallRecordListItem, needTotalEnum, sortTypeEnum } from '@types'
import { TableColumnProps, useTableColumn } from '@hooks'
import { Link } from 'react-router-dom'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { antdSortMap } from '@configs/map'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { callRecordFilterItems, t3cCallRecordFilterItems } from '../../../components/CommonFilters/configs'
import { trimStr, handleCallRecord, ExpandCallRecordDataType, handleT3CCallRecord } from '@library'
import { useTableService } from '@hooks/useTableService'
import { TableProps } from 'antd/lib/table'
import store from '@store'
import { QuestionCircleOutlined } from '@ant-design/icons'

const initFilter: CallRecordListFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

export type CallRecordData = CallRecordListItem & ExpandCallRecordDataType

export default function CallRecordPage () {
  const { userInfo } = store.useSession()
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getCallRecordList, initFilter, '获取通话记录失败')
  const {
    loading: t3cLoading,
    list: t3cList,
    pagination: t3cPagination,
    filter: t3cFilter,
    setFilter: setT3cFilter,
    resetFilter: resetT3cFilter,
    rowKey: t3cRowKey,
  } = useTableService(getT3CCallRecordList, initFilter, '获取t3c通话记录失败')
  const callRecordList = useMemo<CallRecordData[]>(() => handleCallRecord(list), [list])
  const t3cCallRecordList = useMemo<CallRecordData[]>(() => handleT3CCallRecord(t3cList), [t3cList])

  const onTableChange: TableProps<CallRecordListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<CallRecordListFilter>(sorter)
    setFilter(sort)
  }

  const columns: TableColumnProps<CallRecordData>[] = useMemo(() => [
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callBeginTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callEndTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话时长',
      dataIndex: 'durationText',
      width: 120,
    },
    { title: '跟进人', dataIndex: 'salesName', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '满意度', dataIndex: 'satisfactionResultText', width: 120 },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, item) => {
        return (
          <ReplayRecord
            disabled={item.disabled}
            playTips={item.playTips}
            logId={item?.callId}
            title={`与${item?.customerName}的通话录音`}
            key={item?.callId}
          />
        )
      },
    },
  ], [filter.sort, filter.sortType])
  const t3cColumns: TableColumnProps<CallRecordData>[] = useMemo(() => [
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callBeginTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callEndTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: (<>通话时长<Tooltip title="指从通话接通到通话结束的时间"><QuestionCircleOutlined /></Tooltip></>),
      _title: '通话时长',
      dataIndex: 'durationText',
      width: 120,
    },
    { title: '跟进人', dataIndex: 'salesName', width: 260, render: value => <UserAvatar salesName={value} /> },
    { title: '满意度', dataIndex: 'satisfactionResultText', width: 120 },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, item) => {
        return (
          <ReplayRecord
            disabled={item.disabled}
            playTips={item.playTips}
            logId={`t3c-${item.recordId}`}
            title={`与${item?.customerName}的通话录音`}
            key={item?.callId}
          />
        )
      },
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.CALL_RECORD, columns, ['actions', 'customerName'])
  const [t3cColumnConfigs, t3cColumnProps, t3cForceRequest] = useTableColumn(TableIdEnum.CALL_RECORD, t3cColumns, ['actions', 'customerName'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])
  const t3cTableXWidth = useMemo(() => getTableXwidth(t3cColumnProps), [t3cColumnProps])

  return userInfo.useT3C ? (
    <>
      <PageHeader
        title="通话记录" footer={
          (
            <Tabs>
              <Tabs.TabPane tab="企点通话记录" key="qidian">
                <Card className="mt20 mb20">
                  <CommonFilters
                    className="custom-Label"
                    itemList={callRecordFilterItems}
                    onSubmit={filter => setFilter({ ...filter, page: 1 })}
                    onReset={() => resetFilter(initFilter)}
                  />
                </Card>
                <Card>
                  <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.CALL_RECORD} onSuccess={forceRequest} />
                  <TableDownload className="ml20" exportMethod={exportCallRecordList} filter={filter} />
                  <Table
                    style={{ maxWidth: tableXWidth + 'px' }}
                    tableLayout="fixed"
                    className="mt20"
                    loading={loading}
                    pagination={pagination}
                    scroll={{ x: tableXWidth }}
                    rowKey={rowKey}
                    dataSource={callRecordList}
                    columns={columnProps}
                    onChange={onTableChange}
                  />
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane tab="TCCC通话记录" key="tccc">
                <Card className="mt20 mb20">
                  <CommonFilters
                    className="custom-Label"
                    itemList={t3cCallRecordFilterItems}
                    onSubmit={filter => setT3cFilter({ ...filter, page: 1 })}
                    onReset={() => resetT3cFilter(initFilter)}
                  />
                </Card>
                <Card>
                  <ConfigTableHeader columnConfigs={t3cColumnConfigs} tableId={TableIdEnum.CALL_RECORD} onSuccess={t3cForceRequest} />
                  <TableDownload className="ml20" exportMethod={exportT3CCallRecordList} filter={t3cFilter} />
                  <Table
                    style={{ maxWidth: t3cTableXWidth + 'px' }}
                    tableLayout="fixed"
                    className="mt20"
                    loading={t3cLoading}
                    pagination={t3cPagination}
                    scroll={{ x: t3cTableXWidth }}
                    rowKey={t3cRowKey}
                    dataSource={t3cCallRecordList}
                    columns={t3cColumnProps}
                    onChange={onTableChange}
                  />
                </Card>
              </Tabs.TabPane>
            </Tabs>)
        }
      />
    </>
  ) : (
    <>
      <PageHeader title="通话记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={callRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.CALL_RECORD} onSuccess={forceRequest} />
        <TableDownload className="ml20" exportMethod={exportCallRecordList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={callRecordList}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
