import { useEffect, useState } from 'react'

import { getCustomerIntention, getClueTag, getChosenReasonList } from '@services/lead/hotline'
import { CommonError } from '@types'
import { ClueTagItem, NameCodeItem, ChosenReasonType, ChosenReasonItem } from 'src/types/hotline'

type TErr = CommonError | undefined;
export const useGetClueTag = (): [ClueTagItem[], boolean, TErr] => {
  const [list, setList] = useState<ClueTagItem[]>([])
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<TErr>()

  useEffect(() => {
    (async () => {
      setLoading(true)
      const [data, error] = await getClueTag()
      setLoading(false)
      if (!error) {
        setList(data)
      } else {
        console.log('获取getClueTag失败')
        setErr(error)
      }
    })()
  }, [])
  return [list, loading, err]
}

export const useGetIntentionList = (): [NameCodeItem[], boolean, TErr] => {
  const [list, setList] = useState<NameCodeItem[]>([])
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<TErr>()

  useEffect(() => {
    (async () => {
      setLoading(true)
      const [data, error] = await getCustomerIntention()
      setLoading(false)
      if (!error) {
        setList(data)
      } else {
        console.log('获取getCustomerIntention失败')
        setErr(error)
      }
    })()
  }, [])
  return [list, loading, err]
}

export const useGetChosenReason = (type: ChosenReasonType | null): [ChosenReasonItem[], boolean, TErr] => {
  const [list, setList] = useState<ChosenReasonItem[]>([])
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState<TErr>()

  useEffect(() => {
    (async () => {
      if (type) {
        setLoading(true)
        const [data, error] = await getChosenReasonList(type)
        setLoading(false)
        if (!error) {
          setList(data)
        } else {
          console.log('获取getChosenReasonList失败')
          setErr(error)
        }
      }
    })()
  }, [type])
  return [list, loading, err]
}
