/**
 * 客户相关类型定义
 * @author wzwwu
 */
import { ISortOrder, Pagination, XOR, AsyncTotal } from '@types'
import { RuleItem } from 'async-validator'
import { PublicTags, PrivateTags } from './common'
import { IOrgGroup } from './organazition'
import { flowTypeMap } from '@configs/map'

// 客户信息
export interface CustomerInfo {
  salesChannel: { [key: string]: string }
  channelValidTime: string
  itScale: number // IT规模
  customerId: number // 客户ID
  name: string // 客户名称
  customerType: string // 客户类型
  provinceCode: string // 省份码
  cityCode: string // 市区码
  createTime: string // 客户创建时间
  extend: string // 扩展信息，json格式
  uinRegStatus: IuinRegStatusEnum // UIN是否注册。未注册的时候uin字段数组只有一个
  uin: string[] // uin列表。 uinRegStatus为未注册的时候uin字段数组只有一个
  cid: string
  publicTags: PublicTags[]
  privateTags: PrivateTags[]
  //  以下为需求新增信息 http://tapd.oa.com/20403412/prong/stories/view/1020403412860916555
  agentClientFlag: ISAgentClientEnum // 1:是代客；0：非代客（agentFlag和agentClientFlag不可能同时为1）
  agentFlag: ISAgentEnum // 1:为代理商；0：非代理商
  agentManager: ''
  agentUin: number // 渠道经理 0
  authState: number // 不明 0
  belongModule: number // 1
  businessManager: string //  主销售 ''
  firstOrderDate: string // 首单时间，(格式：2020-11-12) null
  follower: string // 'LanCheng'
  industry: Industry[] // 行业，UIN维度的
  cidIncomeIndustry: IncomeIndustryItem // cid纬度 收入行业
  isKaList: IsKaListEnum // 是否在KA清单 0：否；1：是
  lastModifier: string // 'DuJianHao'
  majorSalesType: MajorSalesTypeEnum // 销售类型 0没主销售、1行业直销(KA)、2区域直销、3渠道销售、4创投BD
  privateTagIds: string // "9"
  publicTagIds: string // "24"
  status: CustomerStatusEnum // 客户是否被转出
  updateTime: string // '2020-11-23 15:34:19'
  predictFollowTime: string // '2020-11-23 15:34:19' 下次跟进时间
  relatedStatus?: LinkedFlagEnum
  productReport: number
  accountList: Array<{
    registerChannelName1?: string;
    registerChannelName2?: string;
    registerChannelName3?: string;
    registerChannelName4?: string;
    isTMSaaSClient?: boolean;
    channelMark: number
    uin: string
    authState?: number
    belongModule: number // 所属模块
    clientType: ClientTypeEnum // 代客类型，具体值http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001919895@toc0
    salesChannels?: { [key: string]: string }[]
    status?: number
    wechatRelations: {
      avatar: string;
      name: string;
      type: number[];
      uin: string;
      followUser: {
        createTime: string;
        remark: string;
        userId: string;
        userName: string;
      }[];
    }[],
  }>
}
// clientType(渠道客户类型)枚举值
export enum ClientTypeEnum {
  NEW = 'new', // 新拓
  OLD = 'old', // 存量-已关联
  OLD_NEW_CHECKING = 'old_newchecking', // 存量-新关联(考核中)
  OLD_NEW_NOT_PASS = 'old_newnotpass', // 存量-新关联(未达标)
  ASSIGN = 'assign', // 指派
  DIRECT = 'direct', // 直销
  DIRECT_NEW_OPP = 'direct_newopp', // 直销(新商机)
}
// 客户是否被转出
export enum CustomerStatusEnum {
  NORMAL = 0,
  TRANFSFER_OUT = 1,
}

export interface Industry {
  uin: string // '100009507744',
  industryName: string // '',
  subIndustryName: string // ''
}

// 是否代理商
export enum ISAgentEnum {
  YES = 1,
  NO = 0,
}

// UIN是否注册
export enum IuinRegStatusEnum {
  YES = 1,
  NO = 0,
}

// 是否代客
export enum ISAgentClientEnum {
  YES = 1,
  NO = 0,
}

// 是否在KA清单
export enum IsKaListEnum {
  YES = 1,
  NO = 0,
}

// 销售类型
export enum MajorSalesTypeEnum {
  NONE = 0, // 0没主销售
  KA = 1, // 1行业直销(KA)
  AREA = 2, // 2区域直销
  CHANNEL = 3, // 3渠道销售
  BD = 4, // 4创投BD
}

// 收入归属类型
// export enum IncomeTypeEnum {
//   NONE = 0, // 0|4中长尾
//   KA = 1, // 1(KA)
//   AREA = 2, // 2区域
//   CHANNEL= 3, // 3渠道
//   LT = 4,
// }

// 沟通有效性枚举、映射
export enum CommunicateResultEnum {
  INVALID = 2,
  VALID = 1
}

// 历史跟进记录过滤器
export interface HistoryFollowListFilter extends ISortOrder, Pagination, AsyncTotal {
  batchId?: number | number[] // 批次ID
  customerId?: number // 客户ID
  taskId?: number // 任务ID
  leadId?: number // 线索ID
  name?: string // 客户名称
  touchStatus?: number[] // 触达状态1
  intentionId?: number[] // 一级客户意向ID
  subIntentionId?: number[] // 二级客户意向ID
  ruleType?: number // 客户类型
  sourcePrimaryTagId?: number // 一级客户来源
  sourceSecondaryTagId?: number // 二级客户来源
  groupId?: number // 所属业务组
  follower?: string | string[] // 当前跟进人
  leadBeginTime?: string // 线索创建开始时间（格式为2020-04-17 16:46:45）
  leadEndTime?: string // 线索创建结束时间（格式为2020-04-17 16:46:45）
  publicTagId?: number | number[] // 公共标签
  privateTagId?: number | number[] // 个人标签
  lastFollowBeginTime?: string // 最后跟进开始时间（格式为2020-04-17 16:46:45）
  lastFollowEndTime?: string // 最后跟进结束时间（格式为2020-04-17 16:46:45）
  qq?: string // qq
  phone?: string // 电话
  uin?: string | string[] // uin
  remark?: string // 跟进内容
  communicateResult?: CommunicateResultEnum // 是否有效跟进
  sort?: 'createTime'
  cid?: string | string[]
  followType?: string | string[] // 跟进方式
}

export interface ApplyConflictParam {
  applicant: string // 必须  申请人
  customerName: string // 必须  撞单客户名称
  cid: string // 必须  撞单客户cid
  followTime: string // 必须  撞单时间
  conflictCustomerName: string // 非必须  被撞单客户名称
  conflictUin: string // 非必须  被撞单客户uin
}

export enum AppealStatusEnum {
  NOAPPEAL = 'noAppeal',
  APPELING = 'appealing',
  APPEALSUCCESS = 'appealSuccess',
  APPEALFAILED = 'appealFailed',
}

export interface ConflictRecordFilter extends Pagination, ISortOrder {
  applicant?: string // 非必须   申请人
  conflictCustomerFollower?: string // 非必须   撞单客户跟进人
  applyId?: number // 非必须   申请id
  applyStatus?: AppealStatusEnum // 非必须   状态
  conflictCid?: string // 非必须   撞单cid
  conflictCustomerName?: string // 非必须   撞单客户名称
  createTimeTo?: string // 非必须   撞单开始时间
  createTimeFrom?: string // 非必须   撞单结束时间
  sort?: 'createTime' // 非必须   排序
}

export interface ConflictRecordItem {
  applyId: number // 非必须 申请id
  applyStatus: AppealStatusEnum // 非必须
  applyMsg: string // 非必须 申诉结果信息
  customerName: string // 非必须
  applicant: string // 非必须
  cid: string // 非必须
  customerId: number // 非必须
  customerType: CustomerTypeEnum // 非必须
  followTime: string // 非必须
  conflictCustomerName: string // 非必须
  conflictCid: string // 非必须
  conflictUin: string // 非必须
  conflictCustomerFollower: string // 非必须
  conflictCustomerId: number // 非必须
  createTime: string // 非必须
  updateTime: string // 非必须
  belongModule: number // 非必须
  approvalId: string // 非必须

}

// 历史跟进记录项
export interface HistoryFollowListItem {
  cid: string
  followRecordId: number // 跟进 id
  createTime: string // 跟进时间
  follower: string // 跟进人
  creator: string // 当前记录填写的跟进人
  customerId: number // 客户 id
  customerName: string // 客户名称
  taskIds: string // 任务ID合集，后端以逗号分割
  leadIds: string // 线索ID合集，后端以逗号分割
  leadId: number // 线索ID deprecated
  leadName: string // 线索名称 deprecated
  followType: string[] // 沟通方式
  // phoneRecordUrl: string // 电话录音URL
  // smsContent: string // 短信内容
  communicateResult: string // 沟通是否有效
  intentionParentId: number // 废弃deprecated
  intentionChildId: number // 废弃deprecated
  intentionId: number
  intentionName: string
  subIntentionId: number
  subIntentionName: string
  intentionProduct: string // 意向产品
  touchStatus: number // 触达状态
  publicTagStr: string // 公共标签
  privateTagStr: string // 个人标签
  extend: string // 扩展信息，json格式
  remark: string // 跟进内容
  nextFollowTime: string // 下次跟进时间
  // callList: string[] // 通话录音id
  // SMSList: string[] // 短信id
  isConflict: IsConflictEnum // 是否撞单
}

// 是否撞单
export enum IsConflictEnum {
  YES = 'y',
  NO = 'n',
}

// 通话记录过滤器
export interface CallRecordListFilter extends ISortOrder, Pagination, AsyncTotal {
  cid?: string[]
  failReason?: CallEndReasonEnum // 通话结束原因
  phone?: string // 被叫电话
  salesName?: string // 客服姓名
  callId?: string[]
  durationFrom?: number // 通话时长范围的最小值
  durationTo?: number // 通话时长范围的最大值
  callNumber?: string // 外呼的主号
  groupId?: number // 服务组
  callStatus?: number // 录音选项
  satisfactionResult?: number[] // 满意度
  startCallTime?: string // 录音开始时间（格式为2020-04-17 16:46:45）
  endCallTime?: string // 录音结束时间（格式为2020-04-17 16:46:45）
  createTimeFrom?: string // 记录生成开始时间（格式为2020-04-17 16:46:45）
  createTimeTo?: string // 记录生成结束时间（格式为2020-04-17 16:46:45）
  sort?: 'callBeginTime' | 'callEndTime' | 'createTime'
}

export enum CallEndReasonEnum {
  OK = 0, // 接听成功;
  REJECT = 1, // 拒接;
  NO_RESPONSE = 2, // 无人接听;
  SHUTDOWN = 3, // 关机;
  NO_SERVICE = 4, // 停机;
  BUSY = 5, // 正在通话;
  CAN_NOT_REACH = 6, // 暂时无法接通;
  EMPTY_NUMBER = 7, // 空号;
  NOT_LOGIN = 8, // 座席sdk未登录;
  WAITING = 9, // 呼叫等待;
  HANGUP_AFTER_RING = 10, // 主叫挂断（被叫振铃后）;
  HANGUP_BEFORE_RING = 11, // 主叫取消（被叫振铃前）
  SYSTEM_ERROR = 13, // 系统错误;
  UNKNOWN_ERROR = 14, // 未知错误;
}

export enum T3CCallEndReasonEnum {
  OK = 1, // 接听成功
  ERROR = 0, // 系统错误
  IVRGIVEUP = 102, // IVR 期间用户放弃
  WAITINGGIVEUP = 103, // 会话排队期间用户放弃
  RINGINGGIVEUP = 104, // 会话振铃期间用户放弃
  NOSEATONLINE = 105, // 无座席在线
  NOTWORKTIME = 106, // 非工作时间
  IVREND = 107, // IVR 后直接结束
  BLACKLIST = 100, // 呼入黑名单
  UNCONNECTED = 2, // 未接通
  RESTRICTEDCALLEE = 108, // 被叫因高风险受限
  TOOMANYREQUEST = 109, // 超频
  RESTRICTEDAREA = 110, // 外呼盲区
  RESTRICTEDTIME = 111, // 外呼时间限制
  UNKNOWN = 201, // 未知状态
  NOTANSWER = 202, // 未接听
  USERREJECT = 203, // 拒接挂断
  POWEROFF = 204, // 关机
  NUMBERNOTEXIST = 205, // 空号
  BUSY = 206, // 通话中
  OUTOFCREDIT = 207, // 欠费
  OPERATORERROR = 208, // 运营商线路异常
  CALLERCANCEL = 209, // 主叫取消
  NOTINSERVICE = 210, // 不在服务区
  CLIENTERROR = 211, // 客户端错误
}

// 通话记录记录项
export interface CallRecordListItem {
  callId: string // 电话录音id // 真正id字段
  recordId: number // 记录id // 这个字段前端没有用到 for now
  salesName: number // salesName 对应客服id
  salesNameCh: string // tcc名 对应客服姓名
  groups: IOrgGroup[] // 服务组
  callNumber: string // 主叫号码
  phone: string // 被叫号码
  // startCallTime: string // 录音开始时间（格式为2020-04-17 16:46:45）
  // endCallTime: string // 录音结束时间（格式为2020-04-17 16:46:45）
  createTime: string // 记录生成时间（格式为2020-0901 16:46:45 - 20200902 16:46:45）
  duration: number // 时长
  satisfactionResult: number // 满意度
  customerId: number // 客户Id
  customerName: string // 客户姓名
  belongModule: number
  callBeginTime: string
  callEndTime: string
  cid: string
  failReason: CallEndReasonEnum // 通话结束原因
}

// 短信记录过滤器
export interface SMSRecordListFilter extends ISortOrder, Pagination, AsyncTotal {
  smsId?: string[]
  contactName?: string // 发送对象
  content?: string // 发送内容
  sendBeginTime?: string // 发送开始时间（格式为2020-04-17 16:46:45）
  sendEndTime?: string // 发送结束时间（格式为2020-04-17 16:46:45
  operator?: string // 操作人
  cid?: string[]
  sort?: 'sendTime'
  themeId?: number // 否 int 野鹤短信模版ID
  replyTimeFrom?: string // 否 timeStamp 回复起始时间
  replyTimeTo?: string // 否 timeStamp 回复结束时间
  replyContent?: string // 否 string 回复内容
}

// 短信记录记录项
export interface SMSRecordListItem {
  id: number // 无用
  smsId: string // 发完短信返回的logId
  contactName: string // 发送对象
  customerId: number // 发送对象id
  content: string // 发送内容
  replyContent: ReplyContentType // 回复内容
  sendTime: string // 发送时间
  sendBeginTime: string // 发送开始时间（格式为2020-04-17 16:46:45）
  sendEndTime: string // 发送结束时间（格式为2020-04-17 16:46:45
  operator: string // 操作人
  themeId: number // 7099,野鹤短信模版ID
  leadId: number // 721963,
  uin: string // "100000975086"
}

export interface ReplyContentType {
  smsRecordId: number // 30,
  replyContent: string // "NN",
  replyTime: string // "2021-06-07 12:15:45"
}

// 线索列表过滤器
export interface LeadListFilter {
  page?: number // 页码
  pageSize?: number // 每页数量
  customerId?: number // 客户ID
  status?: number // 跟进状态（0未处理）
}

// 线索列表项
export interface LeadListItem {
  taskId: number // 任务 id
  taskCreateTime: string // 任务创建时间
  customerId: number // 客户 id
  name: string // 客户名称
  sourcePrimaryTagId: number // 一级线索来源 id
  sourcePrimaryTagName: string // 一级线索来源
  sourceSecondaryTagId: number // 二级线索来源 id
  sourceSecondaryTagName: string // 二级线索来源
  publicTags: { // 公共标签
    publicTagId: number
    publicTagName: string
  }[]
  privateTags: { // 个人标签
    privateTagId: number
    privateTagName: string
  }[]
  leadStatus: number // 线索状态
  follower: string // 跟进人
  groups: { // 群组
    groupId: number
    groupName: string
    creator: string
    manager: string
    belongModule: number
    companyId: number
    createTime: string
    updateTime: string
    groupMember: string
    qidianCallNumber: string
    status: number
  }[]
  leadId: number // 线索ID
  leadName: string // 线索名称
  leadReleaseSource: string // 线索来源
  leadCreateTime: string // 线索创建时间
  uin: string // uin
  demand: string // 需求
  firstFollower: string // 首次跟进人
  currentFollower: string // 当前跟进人
  leadExtend: ExtendItem[] // 扩展信息，json格式
  phone: string // 电话
  qq: string // qq
  email: string // 邮箱
}

// 跟进记录提交参数
export interface FollowRecordPayload {
  cid: string
  taskIds: number[] // 任务ID
  // followType?: number[] // 沟通方式（0无 1电话 2短信 3邮件 4IM 5其他）
  // communicateResult?: number // 沟通是否有效（1有效2无效）
  // intentionParentId?: number // 一级意向ID
  // intentionChildId?: number // 二级意向ID
  // intentionProductIds?: string // 意向产品组，格式为"一级产品ID:二级产品ID"，多个就用;分隔，0表示全部
  extend?: string // 自定义的扩展参数，如[{"key": "predictDealTime", "keyName": "预计成单时间", "keyValue": "2020-04-30 12:45:12"}]
  // remark?: string // 本次跟进内容
  // nextFollowTime?: string // 下次跟进时间（格式为2020-04-21 15:26:12）
  followStatus: number // 跟进状态（1保存，2弃单，3转售后）
}

export enum FollowStatusEnum {
  SAVE = 1,
  /* @deprecated 弃单，暂时废弃掉了 */
  DISCARD = 2,
  TO_AFTER_SALES = 3,
}

export interface IFollowFormItem {
  id: number | string // 表单项id
  key: string // 表单英文key
  keyName: string // 字段中文名
  help?: string // 帮助文案（接口暂无）
  rule?: RuleItem[] // 校验规则(接口暂无)
  type: FormItemType // 字段类型，前端定义
  options?: FormItemOption[] // 选项数组
  order: number // 排序权重，1-100
  defaultValue?: string // 默认值
  isRequire: FormItemIsRequire // 是否必填，1必填，0非必填
  enable: FormItemEnable // 是否启用，1启用，0不启用
  length?: number // 前端显示的字段长度
  editable?: FormItemIsEditable // 是否可编辑，1自定义字段可以编辑，0标准字段不可编辑
  creator?: string // 创建人
  createTime?: string // 创建时间
  updateTime?: string // 更新时间
  extraFormId?: number // 额外表单id
  belongFormType?: number // 所属表单类型
}

export interface UpdateFollowFormItemParams {
  list: Partial<IFollowFormItem>[]
}

export type AddFollowFormItemParams = Omit<Partial<IFollowFormItem>, 'id' | 'order'>

export enum FormItemType {
  SELECT = 1, // 单选
  MUTIL_SELECT = 2, // 多选
  INPUT = 3, // 输入框
  TEXTAREA = 4, // 多行输入框
  FILE = 5, // 文件上传
  DATE = 6, // 时间输入框
  INTENTION = 7, // 客户意向（特殊）
  TOUCHSTATUS = 8, // 触达状态（特殊）
  OPPORTUNITY_PRODUCTS = 9, // 关注产品（特殊）
}

export enum FormItemEnable {
  ENABLE = 1,
  DISABLE = 0,
}

export enum FormItemIsRequire {
  YES = 1,
  NO = 0,
}

export enum FormItemIsEditable {
  YES = 1,
  NO = 0,
}

export interface FormItemOption {
  optionKey: string
  optionName: string
  optionValue: string
}

export interface SearchedCustomerFilter extends Pagination{
  name: string // 必须  企业名称
}

export interface SearchedCustomer {
  eid: string // 必须  企业eid
  name: string // 必须  企业名称
  searchName: string // 必须  检索命中名称
  searchNameType: string // 必须  检索命中名称类型
}

// extend 字段 json 格式化后的项结构
export interface ExtendItem {
  key: string
  keyName: string
  keyValue: string
}

// 客户意向项
export interface CustomerIntentionItem {
  intentionId: number
  intentionName: string
  subIntention: CustomerIntentionItem[]
}

// 客户意向产品项
export interface IntentionProductItem {
  intentionId: number
  intentionName: string
  subIntention: IntentionProductItem[]
}

export enum ProductEnum {
  ALL = 0,
  ACTIVE = 1,
  DEACTIVE = 2,
}

// 获取行业产品的参数
export interface IndustryProductFilter extends Pagination{
  parentCode?: string // 非必须  父级产品code
  code?: string // 非必须  产品code
  name?: string // 非必须  产品名称
  status?: ProductEnum // 非必须  0:全部列表，1:生效列表，2:失效列表，默认返回生效列表
  level?: 1 | 2 | 3 | 4 // 非必须  等级 不传默认todo 共4级，销售机会使用的是3级，线索是3、4级
  child?: boolean // 非必须  是否需要返回子产品，需要传true，不需要传false，默认false
}

// 获取行业产品的参数
export interface IndustryProductSearchFilter {
  name: string // 非必须  产品名称
  count: number // 条数
  level?: 1 | 2 | 3 | 4 // 非必须  等级 不传默认todo 共4级，销售机会使用的是3级，线索是3、4级
}

// 行业产品
export interface IndustryProduct {
  code: string // 非必须 产品code
  name: string // 非必须 产品名称
  departmentIds: number[] // 非必须
  desc: string // 非必须
  level: 1 | 2 | 3 | 4 // 非必须 等级 // 共4级，销售机会使用的是3级，线索是3、4级
  modifier: string // 非必须
  status: ProductEnum // 非必须 生效状态
  updateTime: string // 非必须
  child: IndustryProduct[] // 非必须 子类
}

// 短信模板列表项
export interface MessageTemplateItem {
  templateId: number
  title: string
  content: string
  createTime: string
  updateTime: string
  lastModifier: string
  belongModule: number
  companyId: number
  status: number
  ruleId: number
  groupRestrictStatus: number
  creator: string
}

// 用户UIN信息
export interface UinInfo {
  cid: string // "09365caa8fab5483446c90aca30dcf95",
  name: string // "黄豪",
  authState?: number
  uin: string // "10494000",
  regTime: string // "2014-05-28 09:25:40"
}

// 打电话发短信所需要的客户或者线索信息，仅需要其中一个就好了 ，这个type也是 打电话参数、获取明文电话号码参数
export type LeadOrCustomer = XOR<{contactId: number}, {leadId: number}>

// 发送短信参数
export interface SendMessagePayload {
  templateId: number // tcc模版id
  leadId?: number // 线索id
  contactId?: number // 联系人id
  customerId?: number // 仅 coc的两个模块需要
  cid?: string
}

// 呼叫用户参数
export type CallCustomerPayload = LeadOrCustomer & {
  customerId?: number // 仅 coc的两个模块需 要
}

// 获取短信内容参数
export interface SmsTemplateContentPayload {
  templateId: number // tcc模版id
  cid?: string
}

// 外呼记录
export interface CallRecord {
  voiceUrlList: string[] // 返回的是两种格式的src的地址，数组长度为2，随便取一个就好
}

// 短信记录
export interface MsgRecord {
  logId: string
  operator: string
  createTime: string
  smsContent: string
}

// 订单记录过滤器
export interface OrderRecordFilter extends Pagination {
  uin: string
  status?: number // 订单状态，1未支付/2已支付/3发货中/4发货成功/5发货失败/6已退款/7已取消/11代付拒绝
  action?: string // 订单类型purchase 新购 renew 续费 upgrade 升配 downgrade 降配 refund 退货退款 modifyNetworkMode 调整带宽模式 modifyNetworkSize 调整带宽大小
  // orderData?: any // 这里类型应该是 RangePickerValue  // from 'antd/lib/date-picker/interface'
  order?: number // 否 int 0倒序1正序
  createTimeFrom?: string
  createTimeTo?: string
  payMode?: number // 付费类型：0 后付费 1 预付费
}

// 获取后付费月度账单列表 上送
export interface PostOrderRecordFilter extends Pagination {
  uin: string
  statMonth?: string // 月份
  productCode?: string // 商品码
}

// 获取后付费月度账单列表 返回对象
export interface PostOrderRecordItem {
  uin: string // 必须
  statMonth: string // 必须
  productCode: string // 必须   商品码
  productCodeName: string // 必须   商品码中文
  subProductCode: string // 必须   子商品码
  subProductCodeName: string // 必须   子商品码中文
  payAmt: string // 必须   实际消耗金额
}

// 获取商品码 返回对象
export interface PostOrderProductCode {
  productCode: string // 必须   商品码
  productCodeName: string // 必须   商品码中文
}

// 订单记录
export interface OrderRecordItem {
  customerId: any
  dealId?: string
  dealName?: string
  bigDealId?: string
  goodsCategoryId?: string
  goodsDetail?: {
    type?: string
    subType?: string
    num?: string
    action?: string
    info?: string
    cdbInstanceType?: string
    curDeadline?: string
    mysqlVersion?: string
    timeSpan?: number
    productInfo?: { name: string, value: string }[]
  }
  ownerUin?: string
  platform?: string
  regionId?: string
  appId?: string
  goodsNum?: string
  goodsPrice?: {
    costMonth?: number
    policy?: number
    price?: number
    realCostMonth?: number
    realTotalCost?: number
    result?: boolean
    returnCode?: number
    timeSpan?: number
    totalCost?: number
    unitPrice?: number
  }
  creater?: string
  creatTime?: string
  overdueTime?: string
  payer?: string
  billId?: string
  endTime?: string
  cartRecId?: string
  payStartTime?: string
  payEndTime?: string
  taskStartTime?: string
  taskEndTime?: string
  taskDetail?: {
    taskId?: string
    instanceInfo?: any
  }
  status?: string
  reserved?: string
  retry?: string
  reportFlag?: string
  payMode?: string
  voucherDecline?: string // 代金券抵扣金额
  voucherId?: string
  projectId?: string
  payerMode?: string
  agentPayer?: string
  dealType?: string
  activityId?: string
  phone?: string
  ticket?: string
  goodsExpiredTime?: string
  updateTime?: string
  zoneId?: string
  providerOwnerUin?: string
  totalCost?: string
  realTotalCost?: string
  discount?: string
  price?: string
  dealPayType?: string
  productCode?: string
  subProductCode?: string
  action?: string
  voucherTradeId?: string
  payTradeId?: string
  refundPayer?: string
  timeUnit?: string
}

// 工单记录查询条件
export interface WorkOrderFilter extends Pagination {
  uin: string
}

// 工单记录
export interface WorkOrderItem {
  ticket_id?: string
  uin?: string
  service_channel?: string
  create_time?: string
  question?: string
  status?: number
  responsible?: string
  extern_status?: number
  status_display?: string
  extern_status_display?: string
  service_channel_display?: string
  service_scene_name_list?: string[]
}

export interface WorkOrderRecordItem {
  ticketId: string // string 必须 工单ID
  title: string // string 必须 工单标题
  incidentManager: string // string 必须 事件经理
  currentOperator: string // string 必须
  operator: string // string 必须 经手人 ，逗号分隔
  responsible: string // string 必须 客户代表
  ccPerson: string // string 必须 抄送人 ，逗号分隔
  serviceChannel: number // number 必须 服务通道
  serviceChannelDisplay: string // string 必须
  status: number // number 必须
  statusDisplay: string // string 必须 内部状态描述
  externStatus: number // number 必须 工单状态
  externStatusDisplay: string // string 必须 外部状态描述
  serviceSceneChecked: number // number 必须 工单归档
  serviceSceneDisplay: string // string 必须 归档归档描述
  qcloudCategoryId: number // number 必须 官网分类
  qcloudCategoryDisplay: string // string 必须 官网分类描述
  keyStroke: string // string 必须 按键轨迹
  uRL: string // string 必须 万能链接
  createTime: string // string 必须 创建时间
  closeTime: string // string 必须 结束时间
  question: string // string 必须 事件描述

}

// 客户联系人信息查询条件 注意，不接受分页
export interface ContactInfoFilter {
  cid?: string
}

export enum PhoneStatusEnum {
  DEFAULT = 0,
  EMPTY = 1,
  STOP = 2
}

export enum CallRobotSourceEnum {
  CUSTOMER_LIST = 1,
  LEAD_LIST = 2,
  LEAD_LIST_SENT = 3,
  CALL_RECORD = 4,
}

// 客户联系人信息
export interface ContactInfoItem {
  contactId?: number
  cid?: string
  name?: string
  phone?: string
  blackListType?: BlackListTypeEnum // 是否在黑名单中
  title?: string
  qq?: string
  wechat?: string
  email?: string
  status?: ContactInfoStatusTypeEnum // 0: '未验证'1: '有效'2: '无效'3: '优先推荐'
  contactSourceType?: ContactSourceTypeEnum // 录入来源类型
  phoneStatus?: PhoneStatusEnum
}

export enum BlackListTypeEnum {
  MATCHED = 1,
  UNMATCHED = 0,
}

export enum ContactInfoStatusTypeEnum {
  NOT_VERIFIED = 0, //  '未验证'
  VALID = 1, //  '有效'
  INVALID = 2, //  '无效'
  RECOMMENDED = 3, //  '优先推荐'
  BLACKLIST = 4, //  '拒绝联系'
}

export enum ContactSourceTypeEnum {
  BY_MANUAL = 0, // 手动录入
  BY_LEAD = 1 // 线索带入
}

// 客户分配记录查询条件
// export interface ChangeRecordFilter extends Pagination, ISortOrder {
//   uin?: string | string[]
//   cid?: string | string[]
//   leadName?: string
//   changeType?: number
//   remark?: string
//   operator?: string
//   createTimeFrom?: string
//   createTimeTo?: string
//   sort?: 'createTime'
// }

// 获取客户变更记录查询条件
export interface FlowRecordFilter extends Pagination, ISortOrder, AsyncTotal {
  cid?: string | string[]
  customerName?: string // 客户名
  flowType?: keyof typeof flowTypeMap// 0自动分配1人工分配2释放3废弃4客户进入当前模块5uin进入当前模块6客户合并7客户转出
  remark?: string // 变更原因
  operator?: string // 操作人
  createTimeFrom?: string
  createTimeTo?: string
  sort?: 'createTime'
}

// 获取回流池客户查询条件 排序字段，目前支持 createTime
export interface FlowFilter extends Pagination, ISortOrder {
  cid?: string | string[]
  customerName?: string
  status?: BackflowStatus
  createTimeFrom?: string // 起始创建时间
  createTimeTo?: string
  sort?: 'createTime'
}

export enum BackflowStatus {
  // 0:待回流,
  STAND_BY = 0,
  // 1:回流失败
  ERROR = 1,
}

// 客户流转记录的返回
export interface CustomerFlowItem {
  id: number
  uin: string
  cid: string
  customerId: number
  customerName: string
  customerType: CustomerTypeEnum // 参考 customerTypeMap
  remark: string
  operator: string
  createTime: string
  belongModule: number
  batchId: number// 86,
  batchName: string// "存量转出客户释放后批量回流"
  status: BackflowStatus
  failReason: string // 失败原因，没有则为空字符串
}

export enum CustomerTypeEnum {
  PERSON = 0, // 0: '个人',
  COMPANY = 1, // 1: '企业',
  GOVERMENT = 2, // 2: '政府',
  ORGANIZATION = 3, // 3: '组织',
}

export interface ImportCustomerBackflowFilter {
  uins: string[]
}

export interface ImportCustomerBackflowRes {
  successList: ImportCustomerBackflowItem[]
  failList: ImportCustomerBackflowItem[]
}

export interface ImportCustomerBackflowItem {
  uin: string // "10003",
  index: number // 上送数组uins的下标 // 0,
  msg: string // "客户不在转出池"
}

// 获取客户流转日志 对象
export interface ChangeRecordItem {
  id?: number
  customerId: number // 客户ID
  cid: string // cid
  uin: string // "10003",
  customerName: string // 客户名称
  flowType: keyof typeof flowTypeMap // 流转类型   0自动分配1人工分配2释放3废弃4客户进入当前模块5uin进入当前模块6客户合并7客户转出
  remainCid: string // 保留的cid
  beMergedCid: string // 被合并的cid
  beforeFollowerName: string // 变更前跟进人
  beforeFollowerNameEn: string // 变更前跟进人英文名
  beforeFollowerGroupName: string // 变更前所属群组
  afterFollowerName: string // 变更后跟进人
  afterFollowerNameEn: string // 变更后跟进人英文名
  afterFollowerGroupName: string // 变更后所属群组
  remark: string // 操作原因
  operator: string // 操作人
  createTime: string // 变更时间
  belongModule: number // 所属模块
  beforeCid: string // 变更前cid
  afterCid: string // 变更后cid
}

// 模块tab显示配置
export interface ModuleDetailConfigItem {
  id?: number
  belong_module?: number
  show_tabs?: string
  createtime?: string
  last_update_time?: string
  creator?: string
  updator?: string
}

// 客户资源池查询条件
export interface CustomerFilter extends Pagination, ISortOrder, AsyncTotal {
  cid?: string | string[]
  uin?: string | string[]
  name?: string
  customerType?: number
  groupId?: number
  follower?: string | string[] // 当前跟进人
  lastFollower?: string | string[] // 否 string 最近跟进人
  lastFollowBeginTime?: string // 否 string 最近跟进开始时间
  lastFollowEndTime?: string // 否 string 最近跟进结束时间
  predictFollowTimeFrom?: string // 否 string 下次跟进时间开始
  predictFollowTimeTo?: string // 否 string 下次跟进时间结束
  assignStatus?: string
  createTimeFrom?: string
  createTimeTo?: string
  firstAuthTimeFrom?: string // 首次认证时间范围开始（格式为2020-04-17 16:46:45）
  firstAuthTimeTo?: string // 首次认证时间范围结束（格式为2020-04-17 16:46:45）
  preShouldIncomeLevel?: string
  preShouldIncomeLevelDay?: string
  publicTagId?: number | number[] // 公共标签
  privateTagId?: number | number[] // 个人标签
  currentShouldIncomeLevelDay?: string
  shouldIncomePreFrom?: number
  shouldIncomePreTo?: number
  firstPayTimeFrom?: string // 否 timestamp 首次付费时间开始时间
  firstPayTimeTo?: string // 否 timestamp 首次付费时间结束时间
  lastPayTimeFrom?: string // 否 timestamp 最近一次付费时间开始时间
  lastPayTimeTo?: string // 否 timestamp 最近一次付费时间结束时间
  authState?: number
  payStatus?: PayStatusEnum // 付费状态
  relatedStatus?: LinkedFlagEnum
  recentLinkedTime?: string
  sort?: 'firstPayTime' | 'lastFollowTime' | 'firstAuthTime' | 'createTime' | 'lastRelatedTime' | 'portraitTagWeight'
  lastRelatedTime?: string // 最近关联时间
  companyId?: number // 所属公司id
  type?: CustomerType // 客户类型，1公司池客户0正常客户，公司公海池和客户资源池的list数据和公司公海池下载数据的button都要区分type
  uinIncomeIndustryIds?: number[] // uin二级行业id
}

export enum CustomerType {
  NORMAL, // 0正常客户
  COMPANY, // 1公司池客户
  PURE_COMPANY, // 2公海池客户
}

export enum LinkedFlagEnum {
  READY_ASSIGNED = 0, // 待分配
  LINKED = 1, // 已关联    已有座席跟进，无考察有效期
  UNDER_INVESTIGATED = 2, // 考察中    已有座席跟进，有考察有效期
}

// 客户付费状态
export enum PayStatusEnum {
  UNPAID = 0, // 未付费
  PAID = 1, // 已付费
}

// 客户打标上送参数
export interface UpdateCustomerFilter {
  filter: {
    cid?: string[]
    uin?: string | string[]
    name?: string
    customerType?: number
    groupId?: number
    follower?: string | string[]
    assignStatus?: string
    createTimeFrom?: string
    createTimeTo?: string
  }
  updateResult: {
    updateCustomerPublicTags?: {
      publicTagIds?: number[] // 公共标签id
    },
    updateCustomerPrivateTags?: {
      privateTagIds?: number[] // 个人标签id
    }
    relatedStatus?: LinkedFlagEnum
    itScale?: number // it规模
  }
}

// 释放客户
export interface ReleaseCustomerFilter {
  filter: {
    cid?: string[]
  }
}

export interface PrimaryIndustryIncome {
  primaryIndustryIncomeId: number // : 1,
  primaryIndustryIncomeName: string // : "视频"
}

export interface TransferCustomerToDianShiParam {
  cid: string
  customerName: string // 是 string 客户名称
  customerType: string // 是 int 客户类型0个人1企业2政府3组织
  district: string // 是 string 客户所在区域
  province: string // 是 string 客户所在省
  city: string // 是 string 客户所在市
  industry: string // 是 string 客户所属行业
  intentionLevel?: string // 意向等级一级
  intentionSecondLevel?: string // 意向等级二级
  intentionType: string // 是 string 客户意向类型
  needProduct1: string // 是 string 关注产品一级
  needProduct2: string // 是 string 关注产品二级
  leadId: number // 是 int 线索ID
  expectBuyDate: string // 是 string 意向采购时间
  memberScale: string // 是 string 公司规模
  monthConsumption: string // 是 string IT资金规模（月消耗）
  communicateAttitude: string // 是 string 沟通态度
  position: string // 是 string 联系人身份
  businessDemand: string // 是 string 客户需求
  selectionReason: string // 是 string 入选原因
  cloudVendorUse: string[] // 否 string 云厂商使用情况
  cloudProductUse: string // 否 string 云产品使用情况及折扣
  leadTags: string[] // 是 string 线索标签
}

export interface TransferCustomerParam {
  cid: string
  remark: string // 是 string 转出说明
}

// 客户资源池
export interface CustomerItem {
  customerId: number
  name: string
  cid: string
  customerType: CustomerTypeEnum
  provinceCode: string
  cityCode: string
  createTime: string
  firstAuthTime: string // 首次认证时间
  regTime: string // 注册时间
  updateTime: string
  firstPayTime: string // 首次付费时间，未付费情况下为null
  lastModifier: string
  extend: string
  follower: string
  lastFollowTime: string // 最近跟进时间
  predictFollowTime: string // 下次跟进时间
  lastRelatedTime: string // 最近关联时间
  lastFollower: string // 最近跟进人 英文名
  lastFollowerName: string // 最近跟进人 中文名
  belongModule: number
  payStatus: PayStatusEnum // 付费状态
  recentLinkedTime?: string
  relatedStatus?: LinkedFlagEnum
  preShouldIncomeLevel: string // 上月应收层级
  preShouldIncomeLevelDay: string // 上月应收（日累积）层级
  currentShouldIncomeLevelDay: string // 本月应收（日累积）层级
  shouldIncomePre: number // 本月应收预估
  lastPayTime: string // 最近一次付费时间
  accountInfo: IAccountInfo[]
  groups: {
    groupId: number
    groupName: string
    creator: string
    manager: string
    belongModule: number
    companyId: number
    createTime: string
    updateTime: string
    groupMember: string
    qidianCallNumber: string
    status: number
  }[]
  companyId: number // 所属公司id
  companyName: string // 所属公司名
}

// uin的客户信息
export interface IAccountInfo {
  uin: number
  authState: string // 认证状态
  regTime: string // 注册时间
  authTime: string // 认证时间
  createTime: string // 创建时间
  channelMark: string // 收入归属
  preShouldIncomeLevel: string // 上月应收层级
  preShouldIncomeLevelDay: string // 上月应收（日累积）层级
  currentShouldIncomeLevelDay: string // 本月应收（日累积）层级
  shouldIncomePre: number // 预估本月应收
  firstPayTime: string // 首次付费时间，未付费情况下为null
  lastPayTime: string // 最近一次付费时间
  uinIncomeIndustry: IncomeIndustryItem // uin纬度收入行业
}

// 客户转出池查询条件
export interface TransferFilter extends Pagination, ISortOrder {
  cid?: string | string[]
  uin?: string | string[]
  name?: string
  customerType?: number
  transferTimeFrom?: string
  transferTimeTo?: string
  createTimeFrom?: string // "2020-08-11 14:52:12"
  createTimeTo?: string // "2020-08-11 14:52:12"
  follower?: string // "XiaoHuoZi"
  groupId?: number // "加班使我快乐;拉新一猪;拉新;阿里-抢人的;转化;阿斯顿发热;下班了;电饭锅好;3245;3456;1;测试"
  sort?: 'transferTime'
}

// 客户转出池列表
export interface TransferItem {
  // id?: number
  customerId?: number
  name?: string
  cid?: string
  customerType?: number
  // provinceCode?: string
  // cityCode?: string
  // customerCreateTime?: string
  transferTime?: string
  // operator?: string
  // extend?: string
  lastFollower?: string
  // lastFollowerName?: string
  lastFollowerGroupName?: string
  // beforeBelongModule?: number
  // afterBelongModule?: number
  // transferType?: number
  // remark?: string
  createTime: string // "2020-08-11 14:52:12"
}

// 用户画像
export interface UserPortrait {
  user_info?: {
    most_viewed_pages_in_15days: string
    last_goodsexpiredtime: string
    most_orders_goodtype: string
    activity_info: {
      last_activity_time: string
      activity_name: string
      count: number
    }[]
    have_post_payment_order: string
    free_product_info: {
      spantime: string
      createtime: string
      is_free_use: number
      expiredtime: string
      goodstype: string
    }[]
    owneruin: string
    business2_desc: string
    browser_info: {
      url: string
      relation_product: string
      pv: string
      page_type: string
    }[]
    last_act_name: string
    product_info: {
      is_now_use: number
      count: number
      expiredtime: string
      is_pay: number
      goodstype: string
    }[]
  }
}

// 优惠券搜索条件
export interface VoucherFilter extends Pagination {
  uins: string[]
  voucherId?: string
  goodsType?: number | string[]
  payMode?: string
  status?: number | number[]
  startCreate?: string
  endCreate?: string
}

// 优惠券信息
export interface VoucherItem {
  UIDesc?: string
  UIImage?: string
  UILink?: string
  activateTime?: string
  activityCreator?: string
  activityId?: string
  activityName?: string
  amount?: string
  baseAmount?: string
  clientIp?: string
  createTime?: string
  creator?: string
  endTime?: string
  excludeSubType?: string
  expireMonths?: string
  goodsName?: string
  goodsType?: string
  goodsTypeInfo?: {
    action?: string
    billingItemCode?: string
    goodsName?: string
    goodsTypeNew?: string
    opor?: string
    payMode?: string
    productCode?: string
    settlementCycle?: string
    subBillingItemCode?: string
    subProductCode?: string
    sysUpdateTime?: string
  }
  goodsTypeNew?: string
  id?: string
  isCooperation?: string
  isNewVoucher?: string
  leftAmount?: string
  maxPayTime?: string
  minPayTime?: string
  offer?: string
  offerSource?: string
  originate?: string
  ownerUin?: string
  payMode?: string
  policyId?: string
  presentMoneyExpireDays?: string
  reuse?: string
  sceneType?: string
  seqId?: string
  startTime?: string
  status?: string
  timeSpan?: string
  timeUnit?: string
  title?: string
  uin?: string
  useDeadLine?: string
  usedTime?: string
  voucherId?: string
  voucherPrefix?: string
  voucherType?: string
}

// 创建代金券参数
export interface VoucherCreate {
  uin?: string | number
  title?: string
  activityId?: number
  baseAmount?: number
  amount?: number
  count?: number
  userNumLimit?: number
  reuse?: number
  payMode?: string
  productCodes?: string[]
  subProductCodes?: string[]
  useTime?: string[]
  startTime?: string
  endTime?: string
  useDeadline?: string
  timeSpan?: number
  timeUnit?: string
  actions?: string[]
  minPayTime?: number | string
  maxPayTime?: number | string
  userCondition?: string
  timeType: number
}

// 代金券产品
export interface VoucherProdItem {
  productCode?: string
  chnName?: string
  engName?: string
  productNode?: string
  payNode?: string
  subProducts?: {
    chnName?: string
    engName?: string
    subProductCode?: string
    payMode?: string
    actions?: string[]
  }[]
  actions?: string[]
}

// 优惠券申请单列表
export interface VoucherApplicationItem {
  id?: string
  uin?: string
  applicationId?: string
  status?: string
  createTime?: string
}

// 优惠券详情
export interface VoucherApplication {
  id?: number
  applicationId?: string
  activityId?: number
  applicant?: string
  applyDesc?: string
  policies?: {
    id?: number
    policyId?: number
    applicationId?: string
    activityId?: number
    numRestrictedBy?: number
    userCondition?: {
      activated?: number
      agent?: number
      agentClient?: number
      bigCustomer?: number
      company?: number
      cooperator?: number
      localUser?: number
      longTail?: number
      noPaidDeal?: number
      qcloudNewUser?: number
      qcloudNewUserByIdcard?: number
      realName?: number
      registered?: number
      userPrivilegeCheck?: number
    }
    userNumLimit?: number
    title?: string
    count?: number
    amount?: number
    minPayTime?: string
    maxPayTime?: string
    voucherType?: number
    sceneType?: number
    productCodes?: string[]
    subProductCodes?: string[]
    billingItemCodes?: string[]
    subBillingItemCodes?: string[]
    payMode?: string
    actions?: string[]
    baseAmount?: number
    startTime?: string
    endTime?: string
    useDeadline?: string
    timeSpan?: number
    timeUnit?: string
    creator?: string
    reuse?: number
    amountType?: number
    maxAmount?: number
    sendType?: number
    settlementCycle?: string
    offerType?: number
    timeType?: number
    version?: number
    countPerOffer?: number
    description?: string
    modifyContent?: string
    ext?: string
    recordCreator?: string
    recordType?: number
    recordStatus?: number
    createdAt?: string
    updatedAt?: string
  }[]
  approvalStatus?: number
  approvalId?: number
  activityFt?: string
  activityName?: string
  activityStartTime?: string
  activityEndTime?: string
  activityApprovalStatus?: number
  approvalHistory?: {
    action?: string
    approvalTime?: string
    handler?: string
    opinion?: string
  }[]
  approvalConf?: {
    handler: string[]
    type: string
  }[]
  mailFollowers?: string[]
  policyStatus?: string
  type?: number
  remark?: string
  bufferBillId?: number
  createdAt?: string
  updatedAt?: string
}

export interface VoucherTypeItem {
  activityId?: string
  belongModule?: number
  id?: number
  name?: string
}

export interface IKeyValuePair {
  key: string | number
  value: string
}

export interface RuleTypePayload extends Pagination {
  ruleType?: number | number[] // 否 int 规则类型值
  ruleTypeName?: string // 否 sting 规则类型名称（模糊匹配）
  lastModifier?: number // 否 int 最后修改人
  belongModule?: number
}

export interface IRuleType {
  ruleType: number
  ruleTypeName: string
  belongModule: number
  createTime: string
  updateTime: string
  lastModifier: string
}

export interface IEditRuleTypePayload {
  ruleType: number
  ruleTypeName: string
}

export interface IAddRuleTypePayload {
  ruleTypeName: string
}

export interface ICustomerShouldIncomeLevel {
  currentMonthShouldIncomeLevel: string // 本月应收层级
  currentMonthShouldIncomeLevelDay: string // 本月应收（日累加）层级
  currentMonthShouldIncomePre: string // 预估本月应收
  lastMonthShouldIncomeLevel: string // 上月应收层级
  lastMonthShouldIncomeLevelDay: string // 上月应收（日累加）层级
  lastTwoMonthShouldIncomeLevel: string // 上上月应收层级
  lastTwoMonthShouldIncomeLevelDay: string // 上上月应收（日累加）层级
}

export interface IRegisterInfo {
  country: string // "CN",
  countryName: string // "中国",
  registerTime: string // "2020-07-14 16:55:36",
  appId: number // 1300000712,
  mail: string // "1*******@qq.com",
  phoneNumber: string // "*******4057",
  userName: string // "2"
}

export interface IaccountInfo {
  uin: string// "10808",
  cid: string// "fed978d4cd60854636bb6371747396a6",
  name: string// "北京拓尔思信息系统有限公司",
  authName: string// "富途",
  idCard: string// "440***********5256",
  orgCode: string// "",
  customerType: CustomerTypeEnum// 1,
  accountType: number// -1,
  mdmid: string// "",
  authAddrPrefix: string// "",
  authAddr: string// "",
  addrCode: string// "9700",
  industry: string// "1",
  subIndustry: string// "2",
  invoiceAddrPrefix: string// "",
  invoiceAddr: string// "",
  invoicePcode: string// "",
  regFrom: string// "qcloud.16.16.16",
  regIp: string// "0.0.0.0",
  regTime: string// "2016-05-14 15:32:05",
  authType: number// 7,
  authTime: string// "2019-02-20 15:21:53",
  createTime: string// "2019-05-08 17:01:03",
  updateTime: string// "2020-11-02 17:16:56",
  channelFlag: number// 0,
  industryLevel: string// "",
  phone: string// "152****8561",
  email: string// "653******@qq.com",
  manualAddr: string// "",
  country: string// "CN",
  authState: number// 3
}

// 线索流转记录查询条件
export interface CirculateRecordFilter extends Pagination, ISortOrder, AsyncTotal {
  sort: 'createTime',
  uin?: string | string[]
  cid?: string | string[]
  leadName?: string
  changeType?: number // 0自动分配1人工分配2释放3废弃
  remark?: string
  operator?: string
  createTimeFrom?: string
  createTimeTo?: string
  batchId?: number | number[] // 批次ID
  leadReleaseSource?: number
  sourcePrimaryTagId?: number
  sourceSecondaryTagId?: number
}

// 线索流转记录
export interface CirculateRecordItem {
  id?: number
  cid?: string
  leadName?: string
  uin?: string
  leadId?: number
  changeType?: number
  beforeFollowerName?: string
  beforeFollowerGroupName?: string
  afterFollowerName?: string
  afterFollowerGroupName?: string
  remark?: string
  operator?: string
  createTime?: string
  belongModule?: number
  customerId: number
}

export interface UinBaseInfoType {
  salesChannels?: { [key: string]: string }[]
  uin?: string;
  channelMark?: number // 收入来源，具体值http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000504761
  authState?: number // 认证状态 见相关map
  authTime?: string // 认证时间
  regTime?: string // 注册时间
  uinIncomeIndustry?: IncomeIndustryItem // uin纬度收入行业
}

// 记录处理状态
export enum BoundStatusEnum {
  // 0:待处理,
  STAND_BY = 0,
  // 1:待解绑
  WAIT = 1,
  // 2:解绑成功
  SUCCESS = 2,
  // 3:解绑失败
  ERROR = 3,
}

// 解绑方式
export enum BoundTypeEnum {
  // 0:自动,
  AUTO = 0,
  // 1:手动
  MANUAL = 1,
}

// 收入行业
export interface IncomeIndustryItem {
  first: {id: number, name: string},
  second: {id: number, name: string}
}

// 代客解绑列表查询参数
export interface SmsReplyAutoBoundListPayload extends Pagination {
  customerName?: string // 否 string 客户名称
  unboundType?: BoundTypeEnum // 否 int 解绑状态：解绑方式0自动解绑1手动解绑
  status?: BoundStatusEnum // 否 int 记录处理状态0待处理1待解绑2解绑成功3解绑失败
  replyTimeFrom?: string // 否 timeStamp 短信回复开始时间
  replyTimeTo?: string // 否 timeStamp 短信回复结束时间
  cid?: string[] // 否 string array cid
  uin?: string[] // 否 string array uin
  agentUin?: string[] // 否 string array 代理商id
  sort?: string // 否 string 排序字段，目前支持replyTime
  sortType?: string // 否 string 排序方式，ASC/DESC
}

// 代客解绑列表
export interface SmsReplyAutoBoundItem {
  id: string // 1,
  uin: string // "100000032076",
  cid: string // "2db8f9849fe07a9951f2a9ef4f26e88c",
  customerId: string // 415084,
  customerName: string // "杭州合致行企业管理咨询有限公司",
  belongModule: number // 5,
  smsRecordId: number // 30,
  agentId: string // "100000032030",
  agentName: string // "经营测试账号1",
  clientType: string // "assign",
  replyContent: string // "N",
  replyTime: string // "2021-08-09 10:36:20",
  status: number // 2,
  unboundType: number // 2,
  remark: string // "",
  createTime: string // "2021-08-09 10:58:03"
}

// 获取渠道指派失败日志 查询参数
export interface BindFailureRecordPayload extends Pagination, AsyncTotal {
  uin?: string | string[]
  bindTimeFrom?: string // 非必须 绑定开始时间
  bindTimeTo?: string // 非必须 绑定结束时间
}

// 代客解绑列表
export interface BindFailureRecordItem {
  missionId: number //  必须
  missionType: number //  必须
  relatedId: string //  必须
  status: number //  必须
  creator: string //  必须
  createTime: string //  必须
  bindTime: string //  必须 指派时间
  belongModule: number //  必须
  extend: string //  必须 扩展
  message: string //  必须 失败原因
  uin: string //  必须 uin
}

export interface CustomerNameFilter extends Pagination, AsyncTotal{
  customerName?: string // 客户名称
}

export interface ICustomerName {
  name?: string
}

// 磐石二级行业列表
export interface SecondIndustryItem {
  primaryIndustryIncomeId: number // 一级行业ID
  primaryIndustryIncomeName: string // 一级行业名称
  child: {
    secondaryIndustryIncomeId: number // 二级行业ID
    secondaryIndustryIncomeName: string // 二级行业名称
  }[]
}

// 查询工单评论 返回结构
export interface CustomerTicketComment {
  commentId: number //  必须
  ticketId: string //  必须
  title: string //  必须 工单标题
  innerReply: string //  必须 工单评论
  operationId: number //  必须
  cid: string //  必须
  uin: string //  必须
  creator: string //  必须
  createTime: string //  必须
  belongModule: number //  必须
  avatar: string //  必须
}

// 新增工单 上送
export interface CustomerTicketCommentParams {
  ticketId: string //  必须
  title: string //  必须
  innerReply: string //  必须
  cid: string //  必须
  uin: string //  必须
}

// 获取客户意向流转记录 上送
export interface IntentionChangeFilter {
  cid: string //  必须
  phone?: string //  非必填，如果不填，则取客户联系人优先推荐的电话号码
}

// 获取客户意向流转记录 返回结构
export interface IntentionChangeItem {
  id: number //   必须
  taskId: number //   必须 任务id 15544，changeType字段 标记为呼入；任务id 14012，标记为呼出
  createTime: string //   必须
  name: string //   必须 联系人姓名
  company: string //   必须 客户名称
  intentionLevel: string //   必须一级意向
  intentionSecondLevel: string //   必须二级意向
  customerDemand: string //   必须客户需求
  leadTags: string[] //   必须线索标签 item 类型: string
  changeType: string //   必须 流转类型，呼入、呼出
}

export interface OpportunityTypeItem {
  id: number,
  belongModule: number, // 所属模块
  typeId: number, // 机会类型id
  typeName: string, // 机会类型名称
  createtime: string,
  lastUpdateTime: string,
  creator: string,
  updator: string,
}

export interface StageInProgressTypeItem {
  id: number,
  stageKey: string, // 阶段id
  stageName: string, // 阶段名称
  createtime: string,
  lastUpdateTime: string,
  creator: string,
  updator: string,
}
