/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from 'react'
import './style.less'
import { Card, Form, Input, Select, Cascader, DatePicker, Space, Button, Modal } from 'antd'
import { EventRef, FieldType, CallSuccessParams } from 'src/types/hotline'
import { useGetClueTag, useGetIntentionList, useGetChosenReason } from '../hooks'
// import { customerIntention } from 'src/components/CommonTreeSelector/configs'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { parse } from 'qs'
import { useMemoizedFn, useDebounceFn, useMount } from 'ahooks'
import { clueCollectSubmitData } from '@services/lead/hotline'
import CallHistory from '../components/CallHistory'
import SearchCompany from '../components/AutoCompleteCompany'
import MajorSalesType from '../components/MajorSalesType'
import SelectIndustry from '../components/SelectIndustry'
import { TelphoneReg, DutiesList, CompanySize, ClueSourceCommon } from '../constant'
import CascaderProvinceCity from '../components/CascaderProvinceCity'
import TreeSelectProduct from '../components/TreeSelectProduct'
import { envMap, Environment } from 'src/library/rum'
import store from '@store'
import { getStaffName } from '@library'

const ClueCollect400 = () => {
  const { userInfo } = store.useSession()
  const callHistoryRef = useRef<EventRef>(null)
  const [clueTagList] = useGetClueTag()
  const [intentionList] = useGetIntentionList()
  const [form] = Form.useForm<FieldType>()
  const [sitdownName, setSitdownName] = useState(getStaffName(userInfo?.salesName, userInfo?.salesNameCh))
  const [loading, setLoading] = useState(false)
  const searchHistoryParams = useRef<{ uin?: string; phone?: string; company?: string }>({})
  // const history = useHistory()

  const reactlocation = useLocation()
  const params = parse(reactlocation.search, { ignoreQueryPrefix: true, decoder: (str) => str }) as {
    [key: string]: string;
  }
  const { qd_tel: qdTel, qd_callid: qdCallid, qd_kfext: qdKfext, from } = params
  let telephone = qdTel
  if (telephone && telephone.startsWith('0086')) {
    telephone = telephone.replace('0086', '')
  }
  const initValue = {
    sitdown_name: sitdownName,
    chosen_reason: from,
    consultate_time: dayjs(),
    telephone,
  }
  const [chosenReasonList] = useGetChosenReason('400')
  const env = envMap[window.location.hostname] || Environment.production
  const taskIds = env === 'production' ? [17680] : [1558810]

  const submitData = useMemoizedFn(async (param: FieldType, restFn) => {
    const { sitdown_name: sitdownName, product_tree: productTree, chosen_reason: chosenReason } = param

    const chosenReasonInfo = chosenReasonList.find((i) => i.reason_code === chosenReason)
    const chosenReasonCn = chosenReasonInfo?.reason ?? ''

    const params = {
      ...param,
      uin: (param?.uin ?? '').trim(),
      clue_source: [param.clue_source],
      consultate_time: dayjs(param.consultate_time).format('YYYY-MM-DD HH:mm:ss'),
      chosen_reason: chosenReasonCn,
      product_tree: Array.isArray(productTree)
        ? productTree.map((item) => JSON.parse(item as unknown as string))
        : (productTree as unknown as string)
            ?.split('#')
            ?.map((item) => JSON.parse(item as unknown as string)),

      taskId: taskIds[0],
    }
    delete params.major_sales_type
    Object.assign(params, { qd_callid: qdCallid, qd_kfext: qdKfext })
    const [, err] = await clueCollectSubmitData(params as CallSuccessParams)
    if (err) {
      // message.error(`提交失败, 原因${err.message}`);
      // setSubmitLoading(false);
      Modal.error({
        title: '提交失败',
        content: `提交失败, 原因${err.message}`,
      })

      return false
    }
    if (sitdownName) {
      setSitdownName(sitdownName)
    }
    Modal.success({
      content: '提交成功',
      onOk () {
        restFn()
      },
    })
    return true
  })
  const onFinish = async (val: FieldType) => {
    setLoading(true)
    await submitData({ ...val }, form.resetFields)
    setLoading(false)
    // if (isOk) {
    // }
  }

  const onChangeUin = (uin) => {
    searchHistoryParams.current = { ...searchHistoryParams.current, uin }
    callHistoryRef.current?.getHistoryList(searchHistoryParams.current)
  }

  const HMLIncubated = ['H', 'M', 'L', 'incubated']
  const customerIntention = Form.useWatch('customer_intention', form) ?? []

  // 公司名称、主销售类型、联系人姓名
  const isRequired1 = ![
    'unable to convert',
    'irrelevant',
    'tencent cloud problem',
    'Product use problem',
  ].includes(customerIntention?.[1])

  // 职务、线索来源、行业、省份/城市、公司规模
  const isRequired2 = HMLIncubated.includes(customerIntention?.[0])

  const isRequired3
  = HMLIncubated.includes(customerIntention?.[0]) || customerIntention?.[1] === 'second inbound'

  const { run: onChangeCompany } = useDebounceFn(
    (company: string) => {
      searchHistoryParams.current = { ...searchHistoryParams.current, company }
      callHistoryRef.current?.getHistoryList(searchHistoryParams.current)
    },
    {
      wait: 600,
    },
  )

  const onChangeTelphone = (phone) => {
    searchHistoryParams.current = { ...searchHistoryParams.current, phone }
    callHistoryRef.current?.getHistoryList(searchHistoryParams.current)
  }

  useMount(() => {
    document.title = `通用产品400线索录入-${qdTel?.substr(-4) ?? ''}`

    if (telephone) {
      searchHistoryParams.current = { ...searchHistoryParams.current, phone: telephone }
      callHistoryRef.current?.getHistoryList(searchHistoryParams.current)
    }
  })
  return (
    <div className="container">
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>通用产品 400热线咨询</span>
            {/* <Button type="primary" onClick={() => history.push(`/call-success${window.location.search}`)}>
              切换至腾讯会议400线索录入表单
            </Button> */}
          </div>
        }
        style={{ margin: '30px 10px 30px 20px', width: '50%' }}
      >
        <Form
          scrollToFirstError
          autoComplete="off"
          form={form}
          labelCol={{ xl: { span: 6 }, xxl: { span: 3 } }}
          wrapperCol={{ xl: { span: 18 }, xxl: { span: 20 } }}
          initialValues={initValue}
          onFinish={onFinish}
        >
          <Form.Item<FieldType>
            label="UIN"
            name="uin"
            rules={[
              {
                required: customerIntention?.[1] === 'only guided authentication',
                message: '请输入UIN',
              },
            ]}
          >
            <Input
              placeholder="请输入UIN"
              allowClear
              onBlur={(v) => {
                const { value } = v.target
                if (typeof value === 'string') {
                  onChangeUin(value.trim())
                }
              }}
              onChange={(v) => {
                if (v.target.value === '') {
                  onChangeUin(v.target.value)
                }
              }}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="公司名称"
            name="company_name"
            rules={[
              {
                required: isRequired1,
                message: '请输入公司名称',
              },
            ]}
          >
            <SearchCompany
              placeholder="请输入公司名称"
              enterButton="查询公司"
              onChange={(v) => {
              // console.log('---公司名称---', v);
                onChangeCompany(v)
              }}
            />
          </Form.Item>

          <Form.Item noStyle dependencies={['company_name']}>
            {({ getFieldValue }) => {
              return (
                <Form.Item<FieldType>
                  label="主销售类型"
                  name="major_sales_type"
                  rules={[
                    {
                      required: isRequired1,
                      message: '主销售类型',
                    },
                  ]}
                >
                  <MajorSalesType companyName={getFieldValue('company_name')} />
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item<FieldType>
            label="联系人姓名"
            name="name"
            rules={[
              {
                required: isRequired1,
                message: '请输入联系人姓名',
              },
            ]}
          >
            <Input placeholder="请输入联系人姓名" allowClear />
          </Form.Item>

          <Form.Item<FieldType>
            label="电话号码"
            name="telephone"
            rules={[
              {
                required: isRequired3,
                message: '请输入电话号码',
                validator: (_: any, value: string) => {
                  if (!value) {
                    return isRequired3 ? Promise.reject(new Error('请输入正确的电话号码')) : Promise.resolve()
                  }
                  if (!!value && TelphoneReg.test(value)) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('请输入正确的电话号码'))
                },
              },
            ]}
          >
            <Input
              placeholder="请输入电话号码"
              onBlur={(v) => {
                const { value } = v.target
                if (TelphoneReg.test(value)) {
                  onChangeTelphone(value)
                }
              }}
              onChange={(v) => {
                if (v.target.value === '') {
                  onChangeTelphone(v.target.value)
                }
              }}
              allowClear
            />
          </Form.Item>

          <Form.Item<FieldType> label="微信号" name="wx">
            <Input placeholder="请输入微信号" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="邮箱" name="email">
            <Input placeholder="请输入邮箱" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="部门" name="department">
            <Input placeholder="请输入部门" allowClear />
          </Form.Item>

          <Form.Item<FieldType>
            label="职务"
            name="duties"
            rules={[{ required: isRequired2, message: '请选择职务' }]}
          >
            <Select
              showSearch
              placeholder="请选择职务"
              optionFilterProp="children"
              filterOption={(input: string, option?: { label: string; value: string }) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={DutiesList.map((i) => ({ value: i, label: i }))}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="公司规模"
            name="company_scale"
            rules={[{ required: isRequired2, message: '请选择公司规模' }]}
          >
            <Select placeholder="请选择公司规模" options={CompanySize.map((i) => ({ value: i, label: i }))} />
          </Form.Item>
          <Form.Item<FieldType> label="公司背景" name="customer_background">
            <Input.TextArea placeholder="请输入公司背景" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="主要业务" name="main_business">
            <Input.TextArea placeholder="请输入主要业务" allowClear />
          </Form.Item>

          <Form.Item<FieldType>
            label="客户需求"
            name="customer_demand"
            rules={[{ required: true, message: '请输入客户需求' }]}
          >
            <Input.TextArea placeholder="请输入客户需求" allowClear rows={12} />
          </Form.Item>

          <Form.Item<FieldType>
            label="入选原因"
            name="chosen_reason"
            rules={[{ required: true, message: '请输入入选原因' }]}
          >
            {/* <SelectChosenReason type={pageType} disabled={pageType === '400'} /> */}
            <Select
              allowClear
              showSearch
              placeholder="请输入入选原因"
              options={chosenReasonList.map((i) => ({
                label: i.reason,
                value: i.reason_code,
              }))}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="行业"
            name="industry"
            rules={[{ required: isRequired2, message: '请输入行业' }]}
          >
            <SelectIndustry />
          </Form.Item>

          <Form.Item<FieldType>
            label="省份/城市"
            name="province_city"
            rules={[{ required: isRequired2, message: '请选择省份/城市' }]}
          >
            <CascaderProvinceCity />
          </Form.Item>

          <Form.Item<FieldType> label="备注" name="remark">
            <Input.TextArea placeholder="请输入备注" allowClear />
          </Form.Item>

          <Form.Item<FieldType>
            label="客户意向"
            name="customer_intention"
            rules={[{ required: true, message: '请选择客户意向' }]}
          >
            <Cascader
              fieldNames={{ label: 'name', value: 'code', children: 'children' }}
              options={intentionList}
              placeholder="请选择客户意向"
              showSearch
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="咨询产品"
            name="product_tree"
            rules={[{ required: isRequired3, message: '请选择咨询产品' }]}
          >
            <TreeSelectProduct />
          </Form.Item>

          <Form.Item<FieldType>
            label="线索标签"
            name="clue_tag"
            rules={[{ required: isRequired3, message: '请选择线索标签' }]}
          >
            {/* <SelectClueTag /> */}
            <Select
              showSearch
              placeholder="请选择线索标签"
              optionFilterProp="label"
              filterOption
              mode="multiple"
              options={clueTagList.map((i) => ({
                label: i.tag_name,
                value: i.tag_code,
              }))}
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="线索来源"
            name="clue_source"
            rules={[{ required: isRequired2, message: '请选择线索来源' }]}
          >
            <Select
              showSearch
              allowClear
              placeholder="请选择线索来源"
              options={ClueSourceCommon.map((i) => ({ value: i, label: i }))}
            />
          </Form.Item>

          <Form.Item<FieldType> label="客户关键字" name="customer_search_keyword">
            <Input placeholder="请输入客户关键字" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="客户第一个问题" name="customer_first_question">
            <Input placeholder="请输入客户第一个问题" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="客户关注的行业网站" name="customer_concerned_industry_website">
            <Input placeholder="请输入客户关注的行业网站" allowClear />
          </Form.Item>

          <Form.Item<FieldType> label="收件地址" name="receiving_address">
            <Input placeholder="请输入收件地址" allowClear />
          </Form.Item>

          <Form.Item<FieldType>
            label="400咨询时间"
            name="consultate_time"
            rules={[{ required: true, message: '400咨询时间' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              disabled
              placeholder="请输入400咨询时间"
              showTime
            />
          </Form.Item>

          <Form.Item<FieldType>
            label="坐席"
            name="sitdown_name"
            rules={[{ required: true, message: '请输入坐席' }]}
          >
            <Input placeholder="请输入坐席" allowClear />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <Space style={{ justifyContent: 'center', display: 'flex' }}>
              <Button type="primary" htmlType="submit" loading={loading}>
                提交
              </Button>
              <Button htmlType="reset" disabled={loading}>
                重置
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card
        title="近6月流转记录"
        style={{ margin: '30px 20px 30px 10px', width: '50%', height: 'fit-content' }}
      >
        <CallHistory
          ref={callHistoryRef}
          clueTagList={clueTagList}
          intentionList={intentionList}
        />
      </Card>
    </div>
  )
}

export default ClueCollect400
