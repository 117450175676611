/**
 * 组织架构相关类型定义
 * @author tylerzzheng
 */

import { Pagination } from './common'

/**
 * 职能组/群组
 */
export interface IOrgGroup {
  groupId: number,
  groupName: string,
  creator: string,
  manager: string[],
  belongModule: number,
  companyId: number,
  companyName: string,
  createTime: string,
  updateTime: string,
  groupMember: string[],
  groupMemberInfoList: Sales[],
  qidianCallNumber: string,
  isSuperiorGroup: boolean, // isSuperiorGroup 是否是上级群组，
  status: number,
  agentId: string,
  superiorGroupId: number
}
export interface Sales {
  id?: number
  salesName?: string
  salesNameCh?: string
}

export enum AccountType {
  NORMAL = 0, // 真实员工
  VIRTUAL = 1, // 虚拟员工
}

/**
 * 查询职能组的筛选条件
 */
export interface OrgGroupFilter extends Pagination {
  companyId?: number
  groupName?: string
  groupIds?: number[]
  status: number // 状态：0正常-1删除
  groupLevel?: GroupLevel
}

export enum GroupLevel {
  SUPERIOR_GROUP = 'superiorGroup',
  SUB_GROUP = 'subGroup',
}

/**
 * 查询
 */
export interface CallNumberFilter {
  companyId: number
}

/**
 * 更新group的类型
 */
export enum UpdateGroupType {
  ADD,
  EDIT,
}

/**
 * 新增职能组时上送
 */
export interface ADDOrgGroupParam {
  groupName?: string
  manager?: string[]
  groupMember?: string[]
  qidianCallNumber?: string
  agentId?: string
  superiorGroupId?: number
  companyId?: number
}

/**
 * 编辑职能组时上送
 */
export interface EditOrgGroupParam {
  groupId: number
  groupName?: string
  manager?: string[]
  groupMember?: string[]
  qidianCallNumber?: string
  agentId?: string
  superiorGroupId?: number
}

/**
 *  获取员工列表筛选项
 */
export interface StaffFilter extends Pagination{
  id?: number
  salesName?: string
  salesNames?: string[]
  salesNameCh?: string
  belongModule?: number
  companyId?: number
  isAdmin?: StaffisAdmin
  leader?: string
  status?: StaffStatus
  lastModifier?: string
  isAssign?: StaffisAssign
  poolNum?: number
  qidianWorkNumber?: string
  openid?: string
  groupId?: number
  relatedPoolNum?: number
  // 按照某个筛选字段排序
  sortOrder?: keyof StaffFilter
  type?: AccountType
}

/**
 * 员工是否是管理员
 */
export enum StaffisAdmin {
  NOTADMIN,
  ISADMIN,
}

/**
 * 员工在职状态
 */
export enum StaffStatus {
  ONDUTY = 1,
  OFFDUTY = 2,
}

/**
 * 员工是否派单
 */
export enum StaffisAssign {
  NO,
  YES,
}

export type IStaffsGroup = Pick<IOrgGroup, 'groupId' | 'groupName' | 'isSuperiorGroup'>
/**
 *  员工对象
 */
export interface IStaff {
  id: number
  avatar: string
  salesName: string
  salesNameCh: string
  belongModule: number
  companyId: number
  companyName: string
  isAdmin: StaffisAdmin
  leader: string
  status: StaffStatus
  lastModifier: string
  createTime: string
  updateTime: string
  isAssign: StaffisAssign
  poolNum: number
  qidianWorkNumber: string
  openid: string
  groups: IStaffsGroup[]
  email: string
  salesPhone: string
  oaAccountId: string
  relatedPoolNum: number
  type: AccountType
  weworkShortChain: string
  inspectedPoolCapacity?: any
}

// 虚拟账号
export interface IVirtualAccount {
  companyId: number,
  salesName: string,
  salesNameCh: string,
  isAssign: number,
  id?: number
}

/**
 *  编辑员工对象上送参数对象
 */
export interface EditStaffFilter {
  id: number
  isAdmin?: StaffisAdmin
  leader?: string
  status?: StaffStatus
  lastModifier?: string
  isAssign?: StaffisAssign
  poolNum?: number
  qidianWorkNumber?: string
  email?: string
  openid?: string
  salesNameCh?: string
  groupId?: number[]
  salesPhone?: string
  oaAccountId?: string
  relatedPoolNum?: number // 关联客户池数量0为不限
  roles: number[]
  weworkShortChain?: string
}

/* 员工角色对象 */
export interface ISalesRole {
  roleId: number// 770,
  name: string// "TCC任务管理员",
  tag?: string// "tcc_task_manager",
  business?: string// "电销",
  businessId?: string// "",
  remark?: string// "可以查看所有任务",
  isGroup?: number// 0, // 意义不明
  applyTime?: string// "2020-10-29 18:23:52",
  groupId?: number// 0, // 角色所属的组ID
}

export interface IRoleLayerFilter {
  tag: string
}

export interface IRoleLayer {
  subRoles: object[]
  roleId: number // 770
  tag: string // "tcc_task_manager",
  name: string // "TCC任务管理员",
  remark: string // "可以查看所有任务",
  type: number // 1,
  hasSub: boolean // false,
  isApply: number // 1, // 是否可以申请
}

export interface LabelInValue {
  label: string
  value: number
  [type: string]: any
}

// 公司列表编辑参数
export interface UpdateCompanyManagerParams {
  companyId: number
  companyName?: string
  manager: string[]
}

export enum ReceiveInfoEnum {
  YES = 1,
  NO = 0,
}

export enum DispatchLeadEnum {
  YES = 1,
  NO = 0,
}

export interface AgentFilter extends Pagination {
  agentName: string // 非必须
  agentId: string // 非必须
}

export interface Agent {
  agentName: string // 非必须
  agentId: string // 非必须 所属代理商UIN
  agentBusiness: { // 代理商业务身份 // [] 非必须 代理商业务身份item 类型: object
    key: string, // id
    value: string, // 名称
  }[],
  receiveInfo: ReceiveInfoEnum // 非必须 是否接受情报 枚举: 0,1
  dispatchLead: DispatchLeadEnum // 非必须 是否符合商机派发条件 枚举: 0,1
  belongModule: number // 非必须
}
