import { getFrom400 as get, postFrom400 as post } from '@library'
import { RespGetCallHistory, NameCodeItem, ClueTagItem, ChosenReasonItem, ChosenReasonType, CallSuccessParams, SearchEnterpriseNameParams, EnterpriseNameItem, PrimaryIndustryIncomeItem, ProvinceCityItem, ProductTreeItem } from 'src/types/hotline'
import { AxiosRequestConfig } from 'axios'
export function getCallHistory (info: { phone?: string; uin?: string; taskIds: number[] }) {
  return post<RespGetCallHistory>('/400node/base_data/get_call_history', info)
}

export function getCustomerIntention () {
  return get<NameCodeItem[]>('/400node/base_data/customer_intention')
}

export function getClueTag () {
  return get<ClueTagItem[]>('/400node/base_data/clue_tag')
}

export function getChosenReasonList (type?: ChosenReasonType) {
  const param: { type?: '400' | 'IM' } = {}
  if (type) {
    param.type = type
  }
  return post<ChosenReasonItem[]>('/400node/base_data/chosen_reason_list', param)
}

export function clueCollectSubmitData (params: CallSuccessParams, config?: AxiosRequestConfig) {
  return post<any>('/400node/hotline/clue-collect/submit_data', params, config)
}

export function postCallSuccessData (params: CallSuccessParams, config?: AxiosRequestConfig) {
  return post<any>('/400node/hotline/call_success/submit_data', params, config)
}

export function searchEnterpriseName (params: SearchEnterpriseNameParams, timeout?: number) {
  if (arguments.length > 1) {
    return post<EnterpriseNameItem[]>('/400node/base_data/enterprise_name_search', params, { timeout })
  }
  return post<EnterpriseNameItem[]>('/400node/base_data/enterprise_name_search', params)
}

export function getCidByCustomerName (params: { name: number | string }) {
  return post<{ Cid: string }>('/400node/base_data/get_cid_by_customer_name', params)
}

export function getCustomerInfoByFields (params: { Cid: string }) {
  return post<{ major_sales_type: number }>('/400node/base_data/get_customer_info_by_fields', params)
}

export function getPrimaryIndustryIncome () {
  return get<PrimaryIndustryIncomeItem[]>('/400node/base_data/primary_industry_income')
}

export function getProvinceCity () {
  return get<ProvinceCityItem[]>('/400node/base_data/province_city')
}

export function getProductTree () {
  return get<ProductTreeItem[]>('/400node/base_data/product_tree')
}

export function getHotline400Config () {
  return get('/400node/base_data/hotline_400_config')
}
