import React, { useState, useMemo } from 'react'
import { PageHeader, Button, Table, message } from 'antd'
import Card from 'antd/lib/card/Card'
import EditT3CConfigModal from './EditT3CConfigModal'
import { addT3CConfig, getT3CConfig, editT3CConfig } from '@services/t3c'
import { T3CConfig, T3CConfigListItem } from '@types'
import { useTableService } from '@hooks/useTableService'
import { getTableXwidth } from '@configs/table'
import { ColumnProps } from 'antd/lib/table/Column'
import { UserAvatar } from '@components'

const initFilter = { page: 1, pageSize: 10 }

const CallOutCutIn = () => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [config, setConfig] = useState<T3CConfig>({
    sdkAppId: undefined,
    uin: undefined,
    secretId: undefined,
    secretKey: undefined,
  })
  const {
    loading,
    list,
    reload,
    pagination,
    rowKey,
  } = useTableService(getT3CConfig, initFilter, '获取配置失败')
  const columns: ColumnProps<T3CConfigListItem>[] = useMemo(() => [
    { title: '子账号uin', dataIndex: 'uin', width: 120 },
    { title: 'TCC SdkAppId', dataIndex: 'sdkAppId', width: 120 },
    { title: 'SecretId', dataIndex: 'secretId', width: 120 },
    { title: '创建人', dataIndex: 'creator', width: 120, render: value => <UserAvatar salesName={value} /> },
    { title: '创建时间', dataIndex: 'createTime', width: 120 },
    {
      title: '操作',
      dataIndex: 'action',
      width: 120,
      render: (_t, record) => (
        <>
          <a
            style={{ float: 'left' }}
            onClick={() => {
              setConfig(record)
              setShowEditModal(true)
            }}
          >
            编辑
          </a>
        </>
      ),
    },
  ], [])
  const handleEdit = async (values: T3CConfig) => {
    const isNew = !(config?.id)
    const action = isNew ? '新增外呼服务配置' : '编辑规则外呼服务配置'
    const service = isNew ? addT3CConfig : editT3CConfig
    const [, err] = await service(values)
    if (err) {
      message.error(`${action}失败(${err.message})`)
      return
    }
    message.success(`${action}成功`)
    setShowEditModal(false)
    reload()
  }
  return (
    <>
      <PageHeader title="外呼服务接入配置" />

      <Card>
        {
          _.isEmpty(list) && (
            <Button
              type="primary"
              onClick={() => {
                setShowEditModal(true)
              }}
            >新增
            </Button>
          )
        }
        <Table
          size="small"
          loading={loading}
          pagination={pagination}
          rowKey={rowKey}
          dataSource={list}
          columns={columns}
          scroll={{ x: getTableXwidth(columns) + 200 }}
        />
      </Card>
      {showEditModal && (
        <EditT3CConfigModal
          config={config}
          onSubmit={handleEdit}
          onCancel={() => {
            setShowEditModal(false)
          }}
        />
      )}
    </>
  )
}

export default CallOutCutIn
