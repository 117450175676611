// import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd'
import { message, AutoComplete, Empty, Input } from 'antd'
import { trim } from 'lodash'
import React, { useState } from 'react'

import { EnterpriseNameItem } from 'src/types/hotline'
import { searchEnterpriseName } from '@services/lead/hotline'

const removeDuplicateData = (arr: EnterpriseNameItem[]) => {
  const cache: { [key: string]: boolean } = {}
  const result = []
  for (const item of arr) {
    const { Name } = item
    if (!cache[Name]) {
      result.push(item)
      cache[Name] = true
    }
  }
  return result
}

type Props = {
  placeholder?: string;
  enterButton?: string;
  style?: React.CSSProperties;
  value?: string;
  onChange?: (value: string) => void;
};

const SearchCompany: React.FC<Props> = (props) => {
  const {
    style = {},
    value = '',
    onChange = () => {},
    placeholder = '请输入客户名称',
    enterButton = '查询客户',
  } = props
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  // 搜索事件
  const onSearch = async (value: string) => {
    const searchVal = trim(value)
    if (searchVal === '') {
      message.warning('请输入有效名称')
      setOptions([])
      return
    }
    setLoading(true)
    const [data, err] = await searchEnterpriseName({ name: value, limit: 50 })
    setOpen(true)
    setLoading(false)
    if (err) {
      message.error('搜索接口异常，请稍后再 试')
      setOptions([])
    } else if (data.length === 0) {
      message.warning('搜索企业名称为空')
      setOptions([])
    } else {
      const list = removeDuplicateData(data)
      setOptions(list.map((i) => ({ value: i.Name, label: i.Name })))
    }
  }

  // onChange事件
  const onInnerChange = (value: string) => {
    onChange(value)
  }

  return (
    <AutoComplete
      value={value}
      style={style}
      open={open}
      options={options}
      onSelect={() => {
        setOpen(false)
      }}
      onBlur={() => {
        setOpen(false)
      }}
      onChange={onInnerChange}
      notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂未搜索到相关" />}
    >
      <Input.Search
        loading={loading}
        placeholder={placeholder}
        enterButton={enterButton}
        onSearch={onSearch}
      />
    </AutoComplete>
  )
}

export default SearchCompany
