
import React, { } from 'react'
import { Moment } from 'moment'
import {
  Button,
  Form,
  Input,
  Modal,
  Alert,
} from 'antd'
import {
  T3CConfig,
} from '@types'

export interface IRuleTimeSlotMoment {
  timeSlotStart: Moment,
  timeSlotEnd: Moment,
}

interface EditT3CConfigModalProps {
  config: T3CConfig
  onSubmit: (payload: T3CConfig) => void
  onCancel: () => void
}

const Tips = () => {
  return (
    <>
      <h3>配置步骤:</h3>
      <div>1、先在<a href="https://cloud.tencent.com/document/product/679/73494" target="_blank" rel="noreferrer">腾讯云官网</a>创建T3C应用</div>
      <div>2、在<a href="https://console.cloud.tencent.com/ccc" target="_blank" rel="noreferrer">云联络中心控制台</a>复制对应信息</div>
      <div>3、根据<a href="https://iwiki.woa.com/p/4009009849#%E5%A6%82%E4%BD%95%E6%96%B0%E5%BB%BA%E4%B8%80%E4%B8%AA%E5%AD%90%E8%B4%A6%E5%8F%B7%E5%AF%86%E9%92%A5" target="_blank" rel="noreferrer">指引</a>获取子账号秘钥</div>
    </>
  )
}

function EditT3CConfigModal (props: EditT3CConfigModalProps) {
  const { config, onCancel, onSubmit } = props
  const [form] = Form.useForm<T3CConfig>()
  const preSubmit = () => {
    console.log('values:', form.getFieldsValue(true))
    onSubmit(form.getFieldsValue(true))
  }
  return (
    <Modal
      open
      width={850}
      maskClosable={false}
      title={config?.id ? '编辑外呼服务配置' : '新增外呼服务配置'}
      okText="提交"
      onCancel={() => onCancel()}
      footer={
        <>
          <Button className="ml30" onClick={() => onCancel()}>取消</Button>
          <Button className="ml30" type="primary" onClick={preSubmit}>提交</Button>
        </>
      }
    >
      <Alert message={<Tips />} type="warning" />
      <Form initialValues={config} form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginTop: 20 }}>
        <Form.Item required name="uin" label="子账号uin">
          <Input />
        </Form.Item>
        <Form.Item required name="sdkAppId" label="T3C SdkAppId">
          <Input />
        </Form.Item>
        <Form.Item required name="secretId" label="子账号SecretId">
          <Input />
        </Form.Item>
        <Form.Item required name="secretKey" label="子账号SecretKey">
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditT3CConfigModal
