import React from 'react'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import './style.less'
const Hotline = () => {
  const history = useHistory()

  return (
    <div>
      <h1>热线接待</h1>
      <div className="page-entrance">
        {/* <Button type="primary" onClick={() => history.push('/lead/hotline/call-success')}>
          腾讯会议400热线录入页面
        </Button> */}
        <Button type="primary" onClick={() => history.push('/lead/hotline/call-im')}>
          腾讯会议IM热线录入页面
        </Button>
        <Button type="primary" onClick={() => history.push('/lead/hotline/clue-collect/400')}>
          通用产品400热线录入页面
        </Button>
        <Button type="primary" onClick={() => history.push('/lead/hotline/clue-collect/im')}>
          通用产品IM热线录入页面
        </Button>
      </div>
    </div>
  )
}

export default Hotline
